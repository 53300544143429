import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import "../Styles/Main_top.css";
import React from "react";

const YangiliklarCard = ({ images, name, alt, category, id }) => {
  const API = useSelector((state) => state.store.api);
  return (
    <div
      className='col-lg-6 col-xl-5 col-md-6 col-sm-12 newcard123 card border'
      // data-aos="fade-right"
      // data-aos-offset="100"
    >
      {images[0] ? (
        <img
          className='newImg'
          src={`${API}/img/news/${images[0].filename}`}
          alt='Common'
        />
      ) : null}
      {/* <img
          src={`${process.env.REACT_APP_API_URL}/img/news/${images[0].filename}`}
          alt={alt}
          className="newsCardImg"
        /> */}

      <div className='news_top text-center'>
        <p>{category}</p>
      </div>

      <Link className='news' to={`/news/${id}`}>
        {name}
      </Link>
    </div>
  );
};

export default YangiliklarCard;
