import React from 'react';
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";

export default function Paginations(props) {
    return (
      <div className="pagination d-flex justify-content-center mt-5 mb-4">
        <Pagination aria-label="Page navigation example">
          <PaginationItem active>
            <PaginationLink href="/hokimiyat">10</PaginationLink>
          </PaginationItem>
          <PaginationItem>
            <PaginationLink href="/hokimiyat-second-page">20</PaginationLink>
          </PaginationItem>
          <PaginationItem>
            <PaginationLink href="/hokimiyat-third-page">30</PaginationLink>
          </PaginationItem>
          <PaginationItem>
            <PaginationLink href="hokimiyat-fourth-page">40</PaginationLink>
          </PaginationItem>
          <PaginationItem>
            <PaginationLink href="/hokimiyat-fifth-page">50</PaginationLink>
          </PaginationItem>
          <PaginationItem>
            <PaginationLink href="/hokimiyat-sixth-page">60</PaginationLink>
          </PaginationItem>
          <PaginationItem>
            <PaginationLink href="/hokimiyat-seventh-page">73</PaginationLink>
          </PaginationItem>
          <PaginationItem>
            <PaginationLink href="/hokimiyat">back</PaginationLink>
          </PaginationItem>
        </Pagination>
      </div>
    );
}
