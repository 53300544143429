import React, { useEffect } from "react";
import hokimiyat from "../Img/hokimiyat.jpg";
import Paginations from "./Pagination";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

function Hokimiyat(props) {
  const lang = useSelector((state) => state.store.lang);
  const { t } = useTranslation();
  useEffect(() => {}, [lang]);
  return (
    <div className="hokimiyat">
      <h5 className="text-center pt-5">
        <Link to="/raqamlar">{t("work.u")}</Link>
      </h5>
      <div className="container">
        <div className="row">
          <div className="mycard2 col-md-8 mb-2 mt-3">
            <div className="row no-gutters">
              <div className="col-md-5">
                <img src={hokimiyat} id="img" alt="..." />
              </div>
              <div className="col-md-7">
                <div className="card_body2">
                  <h5 className="card-title">
                    <Link to="/hokimiyat-fifth-page/atrof-muhit">
                      {t("hokim5.a")}
                    </Link>
                  </h5>
                  <p className="card-text">
                    {t("hokim5.b")}
                    ...
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="mycard2 col-md-8 mb-2 mt-3">
            <div className="row no-gutters">
              <div className="col-md-5">
                <img src={hokimiyat} id="img" alt="..." />
              </div>
              <div className="col-md-7">
                <div className="card_body2">
                  <h5 className="card-title">
                    <Link to="/hokimiyat-fifth-page/kamunal-hizmatlar">
                      {t("hokim5.c")}
                    </Link>
                  </h5>
                  <p className="card-text">
                    {t("hokim5.d")}
                    ...
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="mycard2 col-md-8 mb-2 mt-3">
            <div className="row no-gutters">
              <div className="col-md-5">
                <img src={hokimiyat} id="img" alt="..." />
              </div>
              <div className="col-md-7">
                <div className="card_body2">
                  <h5 className="card-title">
                    <Link to="/hokimiyat-fifth-page/bosh-arxetektori">
                      {t("hokim5.e")}
                    </Link>
                  </h5>
                  <p className="card-text">
                    {t("hokim5.f")}
                    ...
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="mycard2 col-md-8 mb-2 mt-3">
            <div className="row no-gutters">
              <div className="col-md-5">
                <img src={hokimiyat} id="img" alt="..." />
              </div>
              <div className="col-md-7">
                <div className="card_body2">
                  <h5 className="card-title">
                    <Link to="/hokimiyat-fifth-page/sangzor-tekstil">
                      {t("hokim5.g")}
                    </Link>
                  </h5>
                  <p className="card-text">
                    {t("hokim5.h")}
                    ...
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="mycard2 col-md-8 mb-2 mt-3">
            <div className="row no-gutters">
              <div className="col-md-5">
                <img src={hokimiyat} id="img" alt="..." />
              </div>
              <div className="col-md-7">
                <div className="card_body2">
                  <h5 className="card-title">
                    <Link to="/hokimiyat-fifth-page/qishloq-va-suv">
                      {t("hokim5.i")}
                    </Link>
                  </h5>
                  <p className="card-text">{t("hokim5.j")}. ...</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="mycard2 col-md-8 mb-2 mt-3">
            <div className="row no-gutters">
              <div className="col-md-5">
                <img src={hokimiyat} id="img" alt="..." />
              </div>
              <div className="col-md-7">
                <div className="card_body2">
                  <h5 className="card-title">
                    <Link to="/hokimiyat-fifth-page/yer-va-kadastr">
                      {t("hokim5.k")}
                    </Link>
                  </h5>
                  <p className="card-text">
                    {t("hokim5.l")}
                    ...
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="mycard2 col-md-8 mb-2 mt-3">
            <div className="row no-gutters">
              <div className="col-md-5">
                <img src={hokimiyat} id="img" alt="..." />
              </div>
              <div className="col-md-7">
                <div className="card_body2">
                  <h5 className="card-title">
                    <Link to="/hokimiyat-fifth-page/dehqonlar">
                      {t("hokim5.m")}
                    </Link>
                  </h5>
                  <p className="card-text">
                    {t("hokim5.n")}
                    ...
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="mycard2 col-md-8 mb-2 mt-3">
            <div className="row no-gutters">
              <div className="col-md-5">
                <img src={hokimiyat} id="img" alt="..." />
              </div>
              <div className="col-md-7">
                <div className="card_body2">
                  <h5 className="card-title">
                    <Link to="/hokimiyat-fifth-page/irriatsiya-bolimi">
                      {t("hokim5.o")}
                    </Link>
                  </h5>
                  <p className="card-text">
                    {t("hokim5.p")}
                    ...
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="mycard2 col-md-8 mb-2 mt-3">
            <div className="row no-gutters">
              <div className="col-md-5">
                <img src={hokimiyat} id="img" alt="..." />
              </div>
              <div className="col-md-7">
                <div className="card_body2">
                  <h5 className="card-title">
                    <Link to="/hokimiyat-fifth-page/havza-boshqarmasi">
                      {t("hokim5.q")}
                    </Link>
                  </h5>
                  <p className="card-text">
                    {t("hokim5.r")}
                    ...
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="mycard2 col-md-8 mb-2 mt-3">
            <div className="row no-gutters">
              <div className="col-md-5">
                <img src={hokimiyat} id="img" alt="..." />
              </div>
              <div className="col-md-7">
                <div className="card_body2">
                  <h5 className="card-title">
                    <Link to="/hokimiyat-fifth-page/veterinariya-va-chorvachilik">
                      {t("hokim5.s")}
                    </Link>
                  </h5>
                  <p className="card-text">
                    {t("hokim5.t")}
                    ...
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <Paginations />
      </div>
    </div>
  );
}

export default Hokimiyat;
