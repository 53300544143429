import { Routes, Route, Navigate } from "react-router-dom";
import Layout from "./components/Layout";
import Home from "./pages/Home";
import "./App.css";
import {
  Aholimurojaatlarinihokimoznazoratigaoldi,
  VeterinariyaVaChorvachilik,
  BandlikkaQarshiKurashish,
  Дўстликдонмахсулотлари,
  AgrobankBoshqaruvchisi,
  YollardanFoydalanish,
  TadbirkorlarPalatasi,
  TdbirkorlarMurojati,
  TibbiyotBoshVrachi,
  UyJoyKomunalHizmat,
  PensiyaJamgarmasi,
  IqtisodiyotSanoat,
  IrrigatsiyaBolimi,
  Telekamunikatsiya,
  MaktabgachaTalim,
  YoshlarAgentligi,
  HokimiyatSeventh,
  Obodonlashtirish,
  HavzaBoshqarmasi,
  HokimiyatSecond,
  MikroKriditBank,
  YoshlarIttifoqi,
  YoshlarKengashi,
  IbnSiniTexnikum,
  HokimiyatFourth,
  XalqQabulxonasi,
  DavlatHizatlari,
  BoshArxetektori,
  XalqTalimMudiri,
  HokimiyatThird,
  YangilikIdPage,
  HokimiyatFifth,
  SangzorTekstil,
  HokimiyatSixth,
  Epidemologiya,
  Агротехсервис,
  DostlikXayoti,
  SavdogorBanki,
  QishloqVaSuv,
  YerVaKadastr,
  MoliyaMudiri,
  Tashkilotlar,
  Gaznachilik,
  OdamSavdosi,
  IchkiIshlar,
  SuvTaminoti,
  Pillachilik,
  DehqonBozor,
  ArxivBolimi,
  AtrofMuhit,
  JisVaSport,
  JizzaxNeft,
  Statistika,
  TozaHudud,
  AgroSanoat,
  Madaniyat,
  XalqBanki,
  Manaviyat,
  XalqTalim,
  AgroSanot,
  Hokimiyat,
  NurliDon,
  HududGaz,
  Vaksina,
  Yoshlar,
  Xiyobon,
  Numbers,
  Kadastr,
  Mahalla,
  Elektr,
  Masala,
  Adliya,
  Sanoat,
  Dehqon,
  Moliya,
  Soliq,
  Aholi,
  Hokim,
  Savdo,
  Suv,
} from "./components";

function App() {
  return (
    <Layout>
      <Routes>
        <Route exact path='/' element={<Home />} />
        <Route exact path='/news/:id' element={<YangilikIdPage />} />
        {/* <Route path='/yangiliklar' element={<MainTop />} /> */}

        <Route path='/aholi' element={<Aholi />} />

        <Route path='/odamsavdosi' element={<OdamSavdosi />} />

        <Route path='/vaksina' element={<Vaksina />} />
        <Route path='/xiyobon' element={<Xiyobon />} />
        <Route path='/masala' element={<Masala />} />
        <Route path='/raqamlar' element={<Numbers />} />
        <Route path='/tashkilotlar' element={<Tashkilotlar />} />

        <Route exact path='/hokimiyat' element={<Hokimiyat />} />
        <Route path='/hokimiyat/hokim' element={<Hokim />} />
        <Route path='/hokimiyat/moliya' element={<Moliya />} />
        <Route path='/hokimiyat/sanoat' element={<Sanoat />} />
        <Route path='/hokimiyat/savdo' element={<Savdo />} />
        <Route path='/hokimiyat/yoshlar' element={<Yoshlar />} />
        <Route path='/hokimiyat/suv' element={<Suv />} />
        <Route path='/hokimiyat/mahalla' element={<Mahalla />} />
        <Route path='/hokimiyat/moliya-mudiri' element={<MoliyaMudiri />} />
        <Route
          path='/hokimiyat/agrobank-ish-boshqaruvchisi'
          element={<AgrobankBoshqaruvchisi />}
        />
        <Route
          path='/hokimiyat/bandlikka-qarshi-kurashish'
          element={<BandlikkaQarshiKurashish />}
        />
        <Route path='/hokimiyat/toza-hudud' element={<TozaHudud />} />

        <Route
          exact
          path='/hokimiyat-second-page'
          element={<HokimiyatSecond />}
        />
        <Route
          path='/hokimiyat-second-page/xalq-banki'
          element={<XalqBanki />}
        />
        <Route
          path='/hokimiyat-second-page/ichki-ishlar'
          element={<IchkiIshlar />}
        />

        <Route
          path='/hokimiyat-second-page/savdogar-banki'
          element={<SavdogorBanki />}
        />

        <Route
          path='/hokimiyat-second-page/g`aznachilik-bo`limi-boshlog`i'
          element={<Gaznachilik />}
        />
        <Route
          path='/hokimiyat-second-page/statistika'
          element={<Statistika />}
        />

        <Route
          path='/hokimiyat-second-page/dehqon-bozori-direktori'
          element={<DehqonBozor />}
        />
        <Route
          path='/hokimiyat-second-page/mikrokredit-banki'
          element={<MikroKriditBank />}
        />
        <Route
          path='/hokimiyat-second-page/tadbirkorlar-palatasi'
          element={<TadbirkorlarPalatasi />}
        />
        <Route
          path='/hokimiyat-second-page/yoshlar-ittifoqi-ijtimoiy-qollab-quvatlash'
          element={<YoshlarIttifoqi />}
        />
        <Route
          path='/hokimiyat-second-page/pensiya-jamgarmasi'
          element={<PensiyaJamgarmasi />}
        />

        <Route
          exact
          path='/hokimiyat-third-page'
          element={<HokimiyatThird />}
        />
        <Route
          path='/hokimiyat-third-page/arxiv-bolimi'
          element={<ArxivBolimi />}
        />
        <Route
          path='/hokimiyat-third-page/xalq-talim-mudiri'
          element={<XalqTalim />}
        />
        <Route
          path='/hokimiyat-third-page/maktabgacha-talim'
          element={<MaktabgachaTalim />}
        />
        <Route
          path='/hokimiyat-third-page/tibbiyot'
          element={<TibbiyotBoshVrachi />}
        />
        <Route
          path='/hokimiyat-third-page/epidemologiya'
          element={<Epidemologiya />}
        />
        <Route
          path='/hokimiyat-third-page/jismoniy-tarbiya-va-sport'
          element={<JisVaSport />}
        />
        <Route
          path='/hokimiyat-third-page/madaniya-bolimi-mudiri'
          element={<Madaniyat />}
        />
        <Route
          path='/hokimiyat-third-page/yoshlar-agentligi'
          element={<YoshlarAgentligi />}
        />
        <Route
          path='/hokimiyat-third-page/yoshlar-kengashi'
          element={<YoshlarKengashi />}
        />
        <Route
          path='/hokimiyat-third-page/ibn-sino-texnikum-direktori'
          element={<IbnSiniTexnikum />}
        />

        <Route exact path='/aloqa' element={<HokimiyatFourth />} />
        <Route
          path='/hokimiyat-fourth-page/dostlik-hayoti-muhariri'
          element={<DostlikXayoti />}
        />
        <Route
          path='/hokimiyat-fourth-page/manaviyat-bolimi-boshligi'
          element={<Manaviyat />}
        />
        <Route
          path='/hokimiyat-fourth-page/xalq-talim-mudirasi'
          element={<XalqTalimMudiri />}
        />
        <Route path='/hokimiyat-fourth-page/kadastr' element={<Kadastr />} />
        <Route
          path='/hokimiyat-fourth-page/elektr-energiya'
          element={<Elektr />}
        />
        <Route path='/hokimiyat-fourth-page/tuman-gaz' element={<HududGaz />} />
        <Route
          path='/hokimiyat-fourth-page/suv-taminoti'
          element={<SuvTaminoti />}
        />
        <Route
          path='/hokimiyat-fourth-page/obodonlashtirish'
          element={<Obodonlashtirish />}
        />
        <Route
          path='/hokimiyat-fourth-page/telekamunikatsiya'
          element={<Telekamunikatsiya />}
        />
        <Route
          path='/hokimiyat-fourth-page/yollardan-foydalish'
          element={<YollardanFoydalanish />}
        />

        <Route
          exact
          path='/hokimiyat-fifth-page'
          element={<HokimiyatFifth />}
        />
        <Route
          path='/hokimiyat-fifth-page/atrof-muhit'
          element={<AtrofMuhit />}
        />
        <Route
          path='/hokimiyat-fifth-page/kamunal-hizmatlar'
          element={<UyJoyKomunalHizmat />}
        />
        <Route
          path='/hokimiyat-fifth-page/bosh-arxetektori'
          element={<BoshArxetektori />}
        />
        <Route
          path='/hokimiyat-fifth-page/sangzor-tekstil'
          element={<SangzorTekstil />}
        />
        <Route
          path='/hokimiyat-fifth-page/qishloq-va-suv'
          element={<QishloqVaSuv />}
        />
        <Route
          path='/hokimiyat-fifth-page/yer-va-kadastr'
          element={<YerVaKadastr />}
        />
        <Route path='/hokimiyat-fifth-page/dehqonlar' element={<Dehqon />} />
        <Route
          path='/hokimiyat-fifth-page/irriatsiya-bolimi'
          element={<IrrigatsiyaBolimi />}
        />
        <Route
          path='/hokimiyat-fifth-page/havza-boshqarmasi'
          element={<HavzaBoshqarmasi />}
        />
        <Route
          path='/hokimiyat-fifth-page/veterinariya-va-chorvachilik'
          element={<VeterinariyaVaChorvachilik />}
        />

        <Route
          exact
          path='/hokimiyat-sixth-page'
          element={<HokimiyatSixth />}
        />
        <Route
          path='/hokimiyat-sixth-page/pillachilik'
          element={<Pillachilik />}
        />
        <Route
          path='/hokimiyat-sixth-page/agro-sanot'
          element={<AgroSanot />}
        />
        <Route path='/hokimiyat-sixth-page/Soliq' element={<Soliq />} />
        <Route
          path='/hokimiyat-sixth-page/Агротехсервис'
          element={<Агротехсервис />}
        />
        <Route path='/hokimiyat-sixth-page/Adliya' element={<Adliya />} />
        <Route
          path='/hokimiyat-sixth-page/XalqQabulxonasi'
          element={<XalqQabulxonasi />}
        />
        <Route
          path='/hokimiyat-sixth-page/TadbirkorlarMurojati'
          element={<TdbirkorlarMurojati />}
        />
        <Route
          path='/hokimiyat-sixth-page/JizzaxNeft'
          element={<JizzaxNeft />}
        />
        <Route
          path='/hokimiyat-sixth-page/Дўстликдонмахсулотлари'
          element={<Дўстликдонмахсулотлари />}
        />
        <Route path='/hokimiyat-sixth-page/NurliDon' element={<NurliDon />} />

        <Route
          exact
          path='/hokimiyat-seventh-page'
          element={<HokimiyatSeventh />}
        />
        <Route
          path='/hokimiyat-seventh-page/DavlatHizatlari'
          element={<DavlatHizatlari />}
        />
        <Route
          path='/hokimiyat-seventh-page/AgroSanoat'
          element={<AgroSanoat />}
        />
        <Route
          path='/hokimiyat-seventh-page/IqtisodiyotSanoat'
          element={<IqtisodiyotSanoat />}
        />
        <Route
          path='/hokimiyat-seventh-page/DavlatHizatlari'
          element={<DavlatHizatlari />}
        />
        <Route
          path='/hokimiyat-seventh-page/DavlatHizatlari'
          element={<DavlatHizatlari />}
        />
        <Route
          path='/aholimurojaatlarinihokimoznazoratigaoldi'
          element={<Aholimurojaatlarinihokimoznazoratigaoldi />}
        />
        <Route path='*' element={<Navigate to='/' replace />} />
      </Routes>
    </Layout>
  );
}

export default App;
// *#12580*369#
