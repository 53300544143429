import { FiChevronRight, FiChevronLeft } from "react-icons/fi";
import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import "../Styles/Slider.css";
import axios from "axios";

function Slider() {
  const { api, lang } = useSelector((state) => state.store);
  const [index, setIndex] = useState(0);
  const [loadingSlider, setLoadingSlider] = useState(false);
  const [slider, setSlider] = useState([]);

  useEffect(() => {
    const lastIndex = slider.length - 1;
    if (index < 0) {
      setIndex(lastIndex);
    }
    if (index > lastIndex) {
      setIndex(0);
    }
  }, [index, slider]);

  useEffect(() => {
    let slider = setInterval(() => {
      setIndex(index + 1);
    }, 116000);
    return () => {
      clearInterval(slider);
    };
  }, [index]);

  useEffect(() => {
    const getSlider = async () => {
      try {
        setLoadingSlider(true);

        const { data } = await axios.get(`${api}/newsSlide`);
        setLoadingSlider(false);

        setSlider(data);
      } catch (error) {
        console.log(error);
      }
    };

    getSlider();
  }, [api]);

  return (
    <div className='slider'>
      <div className='slider-container'>
        {loadingSlider ? (
          <div className='d-flex justify-content-center '>
            <div className='spinner-border m-5' role='status'>
              <span className='visually-hidden'>Loading...</span>
            </div>
          </div>
        ) : (
          slider.map((slide, slideIndex) => {
            const { _id, name, images } = slide;
            let position = "nextSlide";
            if (slideIndex === index) {
              position = "activeSlide";
            }
            if (
              slideIndex === index - 1 ||
              (index === 0 && slideIndex === slider.length - 1)
            ) {
              position = "lastSlide";
            }
            return (
              <article className={position} key={_id}>
                <Link to={`/news/` + _id} className='title text-center'>
                  {name ? name[`${lang}`] : name?.uz}
                </Link>
                {images[0] ? (
                  <img
                    src={`${api}/img/news/${images[0].filename}`}
                    alt='rasm'
                  />
                ) : null}
                {/* <div className='line'></div> */}
                {/* <div className='date'>123321321312321</div> */}
              </article>
            );
          })
        )}

        <div className='container-fluid d-flex justify-content-between'>
          <button className='prev' onClick={() => setIndex(index - 1)}>
            <FiChevronLeft />
          </button>
          <button className='next' onClick={() => setIndex(index + 1)}>
            <FiChevronRight />
          </button>
        </div>
      </div>
    </div>
  );
}

export default Slider;
