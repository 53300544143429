import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import logo1 from "../../Img/logo2.png";

import "../../Styles/Home.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Dropdown } from "react-bootstrap";
import { FaTelegramPlane, FaYoutube } from "react-icons/fa";
import { AiFillFacebook } from "react-icons/ai";
// import { FaSearch } from "react-icons/fa";
import { BiMenuAltRight } from "react-icons/bi";
import { FaTimes } from "react-icons/fa";
// import senat from "../../Img/hokimiyat_building.png";

/////////////////////////////////////////////
import { updateLang } from "../../redux/slice";
import { useSelector, useDispatch } from "react-redux";
function Nav() {
  const { t, i18n } = useTranslation();

  const dispatch = useDispatch();
  const lang = useSelector((state) => state.store.lang);
  const [style, setStyle] = useState(false);
  const menu_style = {
    display: "flex",
    transform: style ? "translateX(0px)" : "translateX(100%)",
    transition: "0.5s",
    width: "100%",
    height: "100%",
    background: "#06456A",
    flexDirection: "column",
    justifyContent: "space-evenly",
    alignItems: "center",
    padding: "45px 45px",
    position: "fixed",
    top: "0",
    left: "0",
    zIndex: "1",
  };

  const onSelect = (eventKey, event) => {
    event.preventDefault();
    event.persist();
    event.stopPropagation();
    dispatch(updateLang(eventKey));
    i18n.changeLanguage(eventKey);
  };

  const changeMenu = () => {
    setStyle(!style);
  };
  return (
    <>
      <div className='header menu'>
        <div className=' d-flex justify-content-between align-items-center w-100'>
          <Link to='/'>
            <img
              src={logo1}
              style={{ height: "50px", margin: "15px" }}
              alt='rasm'
            />
          </Link>
          <BiMenuAltRight className='menu_button' onClick={changeMenu} />
        </div>

        <div style={menu_style}>
          <FaTimes className='close' onClick={changeMenu} />
          <li>
            <Link
              onClick={changeMenu}
              to='/#news'
              className='text-decoration-none'
            >
              {t("footer.e")}
            </Link>
          </li>
          <li>
            <Link
              onClick={changeMenu}
              to='/#media'
              className='text-decoration-none'
            >
              {t("footer.i")}
            </Link>
          </li>
          <li>
            <Link
              onClick={changeMenu}
              to='/hokimiyat'
              className='text-decoration-none'
            >
              {t("footer.d")}
            </Link>
          </li>
          <li>
            <Link onClick={changeMenu} to='/' className='text-decoration-none'>
              {t("header.a")}
            </Link>
          </li>
          <li>
            <Link onClick={changeMenu} to='#y' className='text-decoration-none'>
              {t("header.b")}
            </Link>
          </li>
          <li>
            <Link
              onClick={changeMenu}
              to='/tashkilotlar'
              className='text-decoration-none'
            >
              {t("footer.g")}
            </Link>
          </li>
          <li>
            <a onClick={changeMenu} href='#q' className='text-decoration-none'>
              {t("header.c")}
            </a>
          </li>
          <li>
            <a
              onClick={changeMenu}
              href='#aloqa'
              className='text-decoration-none'
            >
              {t("footer.a")}
            </a>
          </li>
        </div>
      </div>
      <div className='navjs navbar d-none d-xl-block'>
        <div className='container'>
          <div className='d-flex justify-content-between w-100 '>
            <Link to='/'>
              <img src={logo1} className='logo1' alt='rasm' />
            </Link>
            <ul className='d-flex align-items-center gap-3'>
              <li>
                <Link to='/hokimiyat' className='text-decoration-none'>
                  {t("header.d")}
                </Link>
              </li>
              <li>
                <Link to='/hokimiyat/mahalla' className='text-decoration-none'>
                  {t("footer.f")}
                </Link>
              </li>
              <li>
                <Link to='/tashkilotlar' className='text-decoration-none'>
                  {t("footer.g")}
                </Link>
              </li>
              <li>
                <Link
                  to='/hokimiyat-third-page'
                  className='text-decoration-none'
                >
                  {t("footer.h")}
                </Link>
              </li>
              <li>
                <Link to='#media' className='text-decoration-none'>
                  {t("footer.i")}
                </Link>
              </li>
              <li>
                <Link to='/aloqa' className='text-decoration-none'>
                  {t("footer.a")}
                </Link>
              </li>
              <li>
                <Dropdown className='col-1' onSelect={onSelect}>
                  <Dropdown.Toggle
                    className='dropdown'
                    variant='none'
                    id='dropdown'
                  >
                    {lang}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item
                      href='#/action-1'
                      eventKey='uz'
                      className='text-secondary'
                    >
                      uz
                    </Dropdown.Item>
                    <Dropdown.Item
                      href='#/action-2'
                      eventKey='ru'
                      className='text-secondary'
                    >
                      ru
                    </Dropdown.Item>
                    <Dropdown.Item
                      href='#/action-3'
                      eventKey='en'
                      className='text-secondary'
                    >
                      en
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </li>
              <li className='d-flex'>
                <a
                  target='_blank'
                  rel='noreferrer'
                  href='https://t.me/dustliktumanihokimligi'
                >
                  <FaTelegramPlane size='20px' className='icon ml-3' />
                </a>
                <a
                  target='_blank'
                  rel='noreferrer'
                  href='https://www.youtube.com/channel/UCWdLzQjxAPA_biEX9b4m-gQ'
                >
                  <FaYoutube size='20px' className='icon ml-3' />
                </a>
                <a
                  target='_blank'
                  rel='noreferrer'
                  href='https://www.facebook.com/dostlik.tumani'
                >
                  <AiFillFacebook size='20px' className='icon ml-3' />
                </a>
              </li>
              <li></li>
              <li></li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}

export default Nav;
