import React from "react";
import { Link } from "react-router-dom";
import "../Styles/Main_top.css";
import { useSelector } from "react-redux";

const Dolzarb = ({ name, images, id }) => {
  const API = useSelector((state) => state.store.api);

  return (
    <div className='col-12 row'>
      <div className='d-flex col-lg-12'>
        <div className='newsDz'>
          <img
            className='dzImage'
            src={`${API}/img/news/` + images[0]?.filename}
            alt=''
          />
          <Link
            className='newsDzLink'
            to={{
              pathname: `/news/${id}`,
              state: { id },
            }}
          >
            {name}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Dolzarb;
