import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import "../Styles/yangiliklar.css";

function Aholimurojaatlarinihokimoznazoratigaoldi() {
  const lang = useSelector((state) => state.store.lang);
  const { t } = useTranslation();
  useEffect(() => {}, [lang]);
  return (
    <div>
      <h1>{t("aholimurojaatihokim")}</h1>
    </div>
  );
}

export default Aholimurojaatlarinihokimoznazoratigaoldi;
