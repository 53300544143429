import React, { useEffect } from "react";
import { AiFillEye } from "react-icons/ai";
import { BiTimeFive } from "react-icons/bi";
import "../Styles/News.css";
// import mahalla from "../Img/mahalla.jpg";
import { Row, Col } from "reactstrap";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

function Mahalla() {
  const lang = useSelector((state) => state.store.lang);
  const { t } = useTranslation();
  useEffect(() => {}, [lang]);
  return (
    <div>
      <div className="container">
        <div className="row">
          <h1>{t("xalqtalimmudir.a")}</h1>
          <div className="seen_date d-flex align-items-center">
            <BiTimeFive className="news_icon" />
            <span className="for_icon">2021 {t("month.year")}</span>
            <AiFillEye className="news_icon" />
            <span className="for_icon">1243</span>
          </div>
        </div>
        <div className="row">
          {/* <img src={mahalla} /> */}
          <p style={{ textAlign: "center" }}>
            <p style={{ fontSize: "36px" }}>{t("xalqtalimmudir.b")} </p>.
          </p>
          <p>
            2021 {t("month.year")} 1 {t("month.jun")}:{" "}
          </p>
          <b>{t("xalqtalimmudir.c")}</b>
          <Row className="d-flex justify-content-between">
            <Col lg={6}>
              <b>{t("work.a")}:</b>
              <p>23.02.1979 й. </p>
              <b>{t("work.b")}:</b>
              <p>{t("telekomunitatsy.d")}</p>
              <b>{t("work.c")}:</b>
              <p>{t("work.o")}</p>
              <b>{t("work.d")}:</b>
              <span className="ml-4">{t("xalqtalimmudir.d")}</span>
              <br />
              <strong>{t("work.e")}:</strong>
              <p>{t("work.p")}</p>
            </Col>
            <Col lg={6}>
              <b>{t("work.f")}:</b>
              <p>{t("xalqtalimmudir.e")} </p>
              <b>{t("work.g")}:</b>
              <p>{t("xalqtalimmudir.f")} </p>
              <b>{t("work.h")}:</b>
              <p>{t("xalqtalimmudir.g")}</p>
              <b> {t("work.i")}:</b>
              <p>{t("work.p")}</p>
            </Col>
          </Row>
          <b>{t("work.j")}: </b>
          <p>{t("work.t")}</p>
          <b>{t("work.k")}: </b>
          <p>{t("work.p")}</p>
          <b>{t("work.l")}:</b>
          <p>{t("work.p")}</p>
          <h3 className="text-center">{t("work.m")}</h3>
          <p>
            {t("xalqtalimmudir.h")}
            <br />
            {t("xalqtalimmudir.i")} <br />
            {t("xalqtalimmudir.j")}
          </p>
        </div>
      </div>
    </div>
  );
}

export default Mahalla;
