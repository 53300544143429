import React, { useEffect } from "react";
import { MDBTable, MDBTableHead, MDBTableBody } from "mdb-react-ui-kit";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

export default function App() {
  const lang = useSelector((state) => state.store.lang);
  const { t } = useTranslation();
  useEffect(() => {}, [lang]);
  return (
    <div className="mt-5">
      <div className="data px-5">
        <h1 className="text-center p-4 text-uppercase">{t("numbers.a")}</h1>
        <h3 className="text-center p-2">{t("numbers.b")}</h3>
        <p className="text-end"> 01.07.2021 {t("month.year")} </p>
        <MDBTable bordered borderColor="primary" className="mb-5">
          <MDBTableHead>
            <tr>
              <th scope="col">№ </th>
              <th scope="col">{t("numbers.c")} </th>
              <th scope="col">{t("numbers.d")} </th>
              <th scope="col">{t("numbers.e")}: </th>
            </tr>
          </MDBTableHead>
          <MDBTableBody>
            <tr>
              <th scope="row">1</th>
              <td>{t("numbers.f")} </td>
              <td>{t("numbers.g")} </td>
              <td>98 110-97-99 </td>
            </tr>
            <tr>
              <th scope="row">2</th>
              <td>{t("numbers.h")} </td>
              <td>{t("numbers.i")} </td>
              <td>98 561-77-84 </td>
            </tr>
            <tr>
              <th scope="row">3</th>
              <td>{t("numbers.j")} </td>
              <td>{t("numbers.k")} </td>
              <td>
                97 523-33-69 <br /> 94 342-02-29
              </td>
            </tr>
            <tr>
              <th scope="row">4</th>
              <td>{t("numbers.l")} </td>
              <td>{t("numbers.m")} </td>
              <td>93 940 -77-12 4 </td>
            </tr>
            <tr>
              <th scope="row">5</th>
              <td>{t("numbers.n")} </td>
              <td>{t("numbers.o")} </td>
              <td>98 210-27-22 </td>
            </tr>
            <tr>
              <th scope="row">6</th>
              <td>{t("numbers.p")} </td>
              <td>{t("numbers.q")} </td>
              <td>97 328-30-38 </td>
            </tr>
            <tr>
              <th scope="row">7</th>
              <td>{t("numbers.r")} </td>
              <td>{t("numbers.s")} </td>
              <td>97 482-34-54 </td>
            </tr>
            <tr>
              <th scope="row">8</th>
              <td>{t("numbers.t")}</td>
              <td> {t("numbers.u")}</td>
              <td>94 579-03-00 </td>
            </tr>
            <tr>
              <th scope="row">9</th>
              <td>{t("numbers.v")} </td>
              <td>{t("numbers.w")} </td>
              <td>93 298-74-67 </td>
            </tr>
            <tr>
              <th scope="row">10</th>
              <td>{t("numbers.x")} </td>
              <td>{t("numbers.y")}</td>
              <td>94 192-39-31 </td>
            </tr>
            <tr>
              <th scope="row">11</th>
              <td>{t("numbers.z")} </td>
              <td>{t("numbers.aa")} </td>
              <td>94 191-27-27 </td>
            </tr>
            <tr>
              <th scope="row">12</th>
              <td>{t("numbers.ab")} </td>
              <td>{t("numbers.ac")} </td>
              <td> 99 705 54 79 </td>
            </tr>
            <tr>
              <th scope="row">13</th>
              <td>{t("numbers.ad")} </td>
              <td>{t("numbers.ae")} </td>
              <td> 99 705 54 79 </td>
            </tr>
            <tr>
              <th scope="row">14</th>
              <td>{t("numbers.af")} </td>
              <td> {t("numbers.ag")} </td>
              <td> 93 291-09-63 </td>
            </tr>
            <tr>
              <th scope="row">15</th>
              <td>{t("numbers.ah")} </td>
              <td>{t("numbers.ai")} </td>
              <td> 93 940-34-38 </td>
            </tr>
            <tr>
              <th scope="row">16</th>
              <td>{t("numbers.aj")} </td>
              <td> {t("numbers.ak")} </td>
              <td>93 296-19-27 </td>
            </tr>
            <tr>
              <th scope="row">17</th>
              <td>{t("numbers.al")} </td>
              <td>{t("numbers.am")} </td>
              <td> 94 576-18-07 </td>
            </tr>
            <tr>
              <th scope="row">18</th>
              <td>{t("numbers.an")} </td>
              <td>{t("numbers.ao")} </td>
              <td>
                93 293-19-66 <br /> 90 183-21-03
              </td>
            </tr>
            <tr>
              <th scope="row">19</th>
              <td>{t("numbers.ap")} </td>
              <td>{t("numbers.aq")} </td>
              <td>90 182-52-52 </td>
            </tr>
            <tr>
              <th scope="row">20</th>
              <td> {t("numbers.ar")} </td>
              <td>{t("numbers.as")} </td>
              <td>95 504-01-16 </td>
            </tr>
            <tr>
              <th scope="row">21</th>
              <td>{t("numbers.at")} </td>
              <td>{t("numbers.au")} </td>
              <td>93 940-01-10 </td>
            </tr>
            <tr>
              <th scope="row">22</th>
              <td>{t("numbers.av")}</td>
              <td>{t("numbers.aw")} </td>
              <td>98 561-50-05 </td>
            </tr>
            <tr>
              <th scope="row">23</th>
              <td>{t("numbers.ax")}</td>
              <td>{t("numbers.ay")} </td>
              <td>
                91 500-01-36 <br /> 97 437 16 19
              </td>
            </tr>

            <tr>
              <th scope="row">25</th>
              <td>{t("numbers.ba")}</td>
              <td>{t("numbers.bb")} </td>
              <td> 94 343-24-10</td>
            </tr>
            <tr>
              <th scope="row">26</th>
              <td>{t("numbers.bc")}</td>
              <td>{t("numbers.bd")} </td>
              <td>93 290-79-55 </td>
            </tr>
            <tr>
              <th scope="row">27</th>
              <td>{t("numbers.be")}</td>
              <td>{t("numbers.bf")} </td>
              <td>
                98 190-99-55 <br /> 99 746-10-01 <br />
                72 335-41-83
              </td>
            </tr>
            <tr>
              <th scope="row">28</th>
              <td>{t("numbers.bg")}</td>
              <td>{t("numbers.bh")} </td>
              <td>
                99-556-63-05 <br /> 72 335-44-11
              </td>
            </tr>
            <tr>
              <th scope="row">29</th>
              <td>{t("numbers.bi")}</td>
              <td>{t("numbers.bj")} </td>
              <td>98 116-22-23 </td>
            </tr>
            <tr>
              <th scope="row">30</th>
              <td>{t("numbers.bk")}</td>
              <td>{t("numbers.bl")} </td>
              <td>99 625-22-81 </td>
            </tr>
            <tr>
              <th scope="row">31</th>
              <td>{t("numbers.bm")}</td>
              <td>{t("numbers.bn")} </td>
              <td>99 009-23-83 </td>
            </tr>
            <tr>
              <th scope="row">32</th>
              <td>{t("numbers.bo")}</td>
              <td>{t("numbers.bp")} </td>
              <td> 94 576-75-22</td>
            </tr>
            <tr>
              <th scope="row">33</th>
              <td>{t("numbers.bq")}</td>
              <td>{t("numbers.br")} </td>
              <td>99 357-43-03 </td>
            </tr>
            <tr>
              <th scope="row">34</th>
              <td>{t("numbers.bs")}</td>
              <td>{t("numbers.bt")} </td>
              <td>99 569-87-87 </td>
            </tr>
            <tr>
              <th scope="row">35</th>
              <td>{t("numbers.bu")} </td>
              <td>{t("numbers.bv")} </td>
              <td>91 595-83-68</td>
            </tr>
            <tr>
              <th scope="row">36</th>
              <td>{t("numbers.bw")}</td>
              <td>{t("numbers.bx")}</td>
              <td>94 347-25-74</td>
            </tr>
            <tr>
              <th scope="row">37</th>
              <td>{t("numbers.by")}</td>
              <td>{t("numbers.bz")} </td>
              <td>
                93 292-21-63 <br /> 94 577-13-46
              </td>
            </tr>
            <tr>
              <th scope="row">38</th>
              <td>{t("numbers.ca")}</td>
              <td>{t("numbers.cb")} </td>
              <td>93 134-87-37 </td>
            </tr>
            <tr>
              <th scope="row">39</th>
              <td>{t("numbers.cc")}</td>
              <td>{t("numbers.cd")} </td>
              <td>93 829-88-85 </td>
            </tr>
            <tr>
              <th scope="row">40</th>
              <td>{t("numbers.ce")}</td>
              <td>{t("numbers.cf")} </td>
              <td>
                335-43-04 91 <br />
                208-17-87
              </td>
            </tr>
            <tr>
              <th scope="row">41</th>
              <td>{t("numbers.cg")}</td>
              <td>{t("numbers.ch")} </td>
              <td>99 552-82-08 </td>
            </tr>
            <tr>
              <th scope="row">42</th>
              <td>{t("numbers.ci")}</td>
              <td>{t("numbers.cj")} </td>
              <td>98 261-33-32</td>
            </tr>
            <tr>
              <th scope="row">43</th>
              <td>{t("numbers.ck")} </td>
              <td>{t("numbers.cl")} </td>
              <td> 99-552-70-88</td>
            </tr>
            <tr>
              <th scope="row">44</th>
              <td>{t("numbers.cm")}</td>
              <td>{t("numbers.cn")} </td>
              <td>93 878-69-96 </td>
            </tr>
            <tr>
              <th scope="row">45</th>
              <td>{t("numbers.co")}</td>
              <td>{t("numbers.cp")} </td>
              <td> 90 229-67-09</td>
            </tr>
            <tr>
              <th scope="row">46</th>
              <td>{t("numbers.cq")}</td>
              <td>{t("numbers.cr")} </td>
              <td>
                97 439-99-44 <br /> 99 381 16 18
              </td>
            </tr>
            <tr>
              <th scope="row">47</th>
              <td>{t("numbers.cs")}</td>
              <td>{t("numbers.ct")} </td>
              <td>
                99 114-00-75 <br /> 72 335-50-03
              </td>
            </tr>
            <tr>
              <th scope="row">48</th>
              <td>{t("numbers.cu")}</td>
              <td>{t("numbers.cv")} </td>
              <td>99 500-22-23</td>
            </tr>
            <tr>
              <th scope="row">49</th>
              <td>{t("numbers.cw")}</td>
              <td>{t("numbers.cx")} </td>
              <td> 99 739-22-10</td>
            </tr>
            <tr>
              <th scope="row">50</th>
              <td>{t("numbers.cy")}</td>
              <td>{t("numbers.cz")} </td>
              <td>99 556-75-56</td>
            </tr>
            <tr>
              <th scope="row">51</th>
              <td>{t("numbers.da")}</td>
              <td>{t("numbers.db")} </td>
              <td>99 599-68-31</td>
            </tr>
            <tr>
              <th scope="row">52</th>
              <td>{t("numbers.dc")}</td>
              <td>{t("numbers.dd")} </td>
              <td>93 290-62-35</td>
            </tr>
            <tr>
              <th scope="row">53</th>
              <td>{t("numbers.de")}</td>
              <td>{t("numbers.df")} </td>
              <td>94 343-31-36 </td>
            </tr>
            <tr>
              <th scope="row">54</th>
              <td>{t("numbers.dg")}</td>
              <td>{t("numbers.dh")} </td>
              <td>94 197-02-73 </td>
            </tr>
            <tr>
              <th scope="row">55</th>
              <td>{t("numbers.di")} </td>
              <td>{t("numbers.dj")} </td>
              <td>97 295-33-61</td>
            </tr>
            <tr>
              <th scope="row">56</th>
              <td>{t("numbers.dk")} </td>
              <td>{t("numbers.dl")}</td>
              <td>99 550-50-03 </td>
            </tr>
            <tr>
              <th scope="row">57</th>
              <td>{t("numbers.dm")}</td>
              <td>{t("numbers.dn")} </td>
              <td>94 194-99-79</td>
            </tr>
            <tr>
              <th scope="row">58</th>
              <td>{t("numbers.do")}</td>
              <td>{t("numbers.dp")} </td>
              <td>94 162-52-14 </td>
            </tr>
            <tr>
              <th scope="row">59</th>
              <td>{t("numbers.dq")}</td>
              <td>{t("numbers.dr")}</td>
              <td>
                93 291-47-50 <br /> 99 357-85-44
              </td>
            </tr>
            <tr>
              <th scope="row">60</th>
              <td>{t("numbers.ds")} </td>
              <td>{t("numbers.dt")} </td>
              <td>98 188-12-18 </td>
            </tr>
            <tr>
              <th scope="row">61</th>
              <td>{t("numbers.du")}</td>
              <td>{t("numbers.dv")}</td>
              <td>99 582-32-32 </td>
            </tr>
            <tr>
              <th scope="row">62</th>
              <td>{t("numbers.dw")} </td>
              <td>{t("numbers.dx")} </td>
              <td>94 342-54-33</td>
            </tr>
            <tr>
              <th scope="row">63</th>
              <td>{t("numbers.dy")}</td>
              <td>{t("numbers.dz")} </td>
              <td>99 355 44 66 </td>
            </tr>
            <tr>
              <th scope="row">64</th>
              <td>{t("numbers.ea")}</td>
              <td>{t("numbers.eb")} </td>
              <td>94 915-38-99</td>
            </tr>
            <tr>
              <th scope="row">65</th>
              <td>{t("numbers.ec")} </td>
              <td>{t("numbers.ed")} </td>
              <td>99 557-73-37</td>
            </tr>
            <tr>
              <th scope="row">66</th>
              <td>{t("numbers.ee")} </td>
              <td>{t("numbers.ef")} </td>
              <td>94 344-98-00</td>
            </tr>
            <tr>
              <th scope="row">67</th>
              <td>{t("numbers.eg")}</td>
              <td>{t("numbers.eh")}</td>
              <td>97 484-10-70 </td>
            </tr>
            <tr>
              <th scope="row">68</th>
              <td>{t("numbers.ei")} </td>
              <td>{t("numbers.ej")} </td>
              <td>94 199-70-70</td>
            </tr>
            <tr>
              <th scope="row">69</th>
              <td>{t("numbers.ek")} </td>
              <td>{t("numbers.el")} </td>
              <td>94 190-26-28</td>
            </tr>
            <tr>
              <th scope="row">70</th>
              <td>{t("numbers.em")}</td>
              <td>{t("numbers.en")} </td>
              <td>93 297-07-36 </td>
            </tr>
            <tr>
              <th scope="row">71</th>
              <td>{t("numbers.eo")}</td>
              <td>{t("numbers.ep")} </td>
              <td>99 577-55-90 </td>
            </tr>
          </MDBTableBody>
        </MDBTable>

        <h3 className="text-center p-3">{t("numbers.eq")}</h3>
        <MDBTable bordered borderColor="primary" className="mb-5">
          <MDBTableHead>
            <tr>
              <th scope="col">№ </th>
              <th scope="col">{t("numbers.er")} </th>
              <th scope="col">{t("numbers.es")} </th>
              <th scope="col">{t("numbers.e")}. </th>
            </tr>
          </MDBTableHead>
          <MDBTableBody>
            <tr>
              <th scope="row">1</th>
              <td>{t("numbers.et")}</td>
              <td>{t("numbers.eu")} </td>
              <td> 93 303 65 92 </td>
            </tr>
            <tr>
              <th scope="row">2</th>
              <td>{t("numbers.ev")} </td>
              <td>{t("numbers.ew")} </td>
              <td> 180 62 62</td>
            </tr>
            <tr>
              <th scope="row">3</th>
              <td>{t("numbers.ex")} </td>
              <td>{t("numbers.ey")} </td>
              <td>99 557 88 54 </td>
            </tr>
            <tr>
              <th scope="row">1</th>
              <td>{t("numbers.ez")} </td>
              <td>{t("numbers.fa")} </td>
              <td> 99 067 59 94</td>
            </tr>
          </MDBTableBody>
        </MDBTable>

        <h3 className="text-center p-3">{t("numbers.fb")}</h3>
        <MDBTable bordered borderColor="primary">
          <MDBTableHead>
            <tr>
              <th scope="col">№ </th>
              <th scope="col">{t("numbers.fc")} </th>
              <th scope="col">{t("numbers.fd")} </th>
              <th scope="col">{t("numbers.e")}. </th>
            </tr>
          </MDBTableHead>
          <MDBTableBody>
            <tr>
              <th scope="row">1</th>
              <td>{t("numbers.fe")} </td>
              <td>{t("numbers.ff")} </td>
              <td>94 340-69-80</td>
            </tr>
            <tr>
              <th scope="row">2</th>
              <td>{t("numbers.fg")} </td>
              <td>{t("numbers.fh")} </td>
              <td> 93 322-00-83</td>
            </tr>
            <tr>
              <th scope="row">3</th>
              <td>{t("numbers.fi")} </td>
              <td>{t("numbers.fj")}</td>
              <td>94 193-73-37 </td>
            </tr>
            <tr>
              <th scope="row">4</th>
              <td>{t("numbers.fk")} </td>
              <td>{t("numbers.fl")} </td>
              <td>93 296-73-20 </td>
            </tr>
            <tr>
              <th scope="row">5</th>
              <td>{t("numbers.fm")} </td>
              <td>{t("numbers.fn")} </td>
              <td>93 297-20-72</td>
            </tr>
            <tr>
              <th scope="row">6</th>
              <td>{t("numbers.fo")} </td>
              <td>{t("numbers.fp")} </td>
              <td>99 557-38-32 </td>
            </tr>
            <tr>
              <th scope="row">7</th>
              <td>{t("numbers.fq")} </td>
              <td>{t("numbers.fr")}</td>
              <td>98 116-34-00</td>
            </tr>
            <tr>
              <th scope="row">8</th>
              <td>{t("numbers.fs")} </td>
              <td>{t("numbers.ft")} </td>
              <td>93 306-37-77</td>
            </tr>
            <tr>
              <th scope="row">9</th>
              <td>{t("numbers.fu")} </td>
              <td>{t("numbers.fv")}</td>
              <td>94 197-78-86</td>
            </tr>
            <tr>
              <th scope="row">10</th>
              <td>{t("numbers.fw")} </td>
              <td>{t("numbers.fx")} </td>
              <td> 99 076-69-69</td>
            </tr>
            <tr>
              <th scope="row">11</th>
              <td>{t("numbers.fy")} </td>
              <td>{t("numbers.fz")} </td>
              <td>91 209-75-76</td>
            </tr>
          </MDBTableBody>
        </MDBTable>

        <h3 className="text-center p-3">{t("numbers.ga")}!</h3>
        <MDBTable bordered borderColor="primary">
          <MDBTableHead>
            <tr>
              <th scope="col">№ </th>
              <th scope="col">{t("numbers.gb")}</th>
              <th scope="col">{t("numbers.gc")} </th>
              <th scope="col">{t("numbers.e")}.</th>
            </tr>
          </MDBTableHead>
          <MDBTableBody>
            <tr>
              <th scope="row">1</th>
              <td> ? </td>
              <td>{t("numbers.gd")} </td>
              <td> 72 335-43-72</td>
            </tr>
            <tr>
              <th scope="row">2</th>
              <td>{t("numbers.ge")} </td>
              <td>{t("numbers.gf")} </td>
              <td> 72 335-40-88</td>
            </tr>
            <tr>
              <th scope="row">3</th>
              <td>{t("numbers.gg")} </td>
              <td>{t("numbers.gh")} </td>
              <td> 72 335-40-66</td>
            </tr>
            <tr>
              <th scope="row">4</th>
              <td>{t("numbers.gi")} </td>
              <td>{t("numbers.gj")} </td>
              <td> 72 335-43-40</td>
            </tr>
          </MDBTableBody>
        </MDBTable>

        <h3 className="text-center p-3">{t("numbers.gk")}</h3>
        <MDBTable bordered borderColor="primary">
          <MDBTableHead>
            <tr>
              <th scope="col">№ </th>
              <th scope="col">{t("numbers.gl")}</th>
              <th scope="col">{t("numbers.gm")} </th>
              <th scope="col">{t("numbers.e")} </th>
            </tr>
          </MDBTableHead>
          <MDBTableBody>
            <tr>
              <th scope="row">1</th>
              <td>1-{t("work.ab")} </td>
              <td>{t("numbers.gn")} </td>
              <td>91-592-68-40</td>
            </tr>
            <tr>
              <th scope="row">1</th>
              <td>2-{t("work.ab")} </td>
              <td>{t("numbers.go")} </td>
              <td>99-559-02-87</td>
            </tr>
            <tr>
              <th scope="row">1</th>
              <td>3-{t("work.ab")} </td>
              <td>{t("numbers.gp")} </td>
              <td>94 191 01 08 </td>
            </tr>
            <tr>
              <th scope="row">4</th>
              <td>4-{t("work.ab")} </td>
              <td>{t("numbers.gq")} </td>
              <td>93-299-26-61</td>
            </tr>
            <tr>
              <th scope="row">5</th>
              <td>5-{t("work.ab")} </td>
              <td>{t("numbers.gr")} </td>
              <td>94 199-13-78</td>
            </tr>
            <tr>
              <th scope="row">6</th>
              <td>6-{t("work.ab")} </td>
              <td>{t("numbers.gs")} </td>
              <td>90 229-71-05</td>
            </tr>
            <tr>
              <th scope="row">71</th>
              <td>7-{t("work.ab")} </td>
              <td>{t("numbers.gt")} </td>
              <td>91-195-47-67 </td>
            </tr>
            <tr>
              <th scope="row">8</th>
              <td>8-{t("work.ab")} </td>
              <td>{t("numbers.gu")} </td>
              <td>90 515-00-74</td>
            </tr>
            <tr>
              <th scope="row">9</th>
              <td>9-{t("work.ab")} </td>
              <td>{t("numbers.gv")} </td>
              <td>94 341-81-83</td>
            </tr>
            <tr>
              <th scope="row">10</th>
              <td>10-{t("work.ab")} </td>
              <td>{t("numbers.gw")} </td>
              <td> 93 301-25-85</td>
            </tr>
            <tr>
              <th scope="row">11</th>
              <td>11-{t("work.ab")} </td>
              <td>{t("numbers.gx")} </td>
              <td> 94 572-04-64</td>
            </tr>
            <tr>
              <th scope="row">12</th>
              <td>12-{t("work.ab")} </td>
              <td>{t("numbers.gy")} </td>
              <td>93 294-26-55 </td>
            </tr>
            <tr>
              <th scope="row">13</th>
              <td>13-{t("work.ab")} </td>
              <td>{t("numbers.gz")} </td>
              <td> 94 345 17 70</td>
            </tr>
            <tr>
              <th scope="row">14</th>
              <td>14-{t("work.ab")} </td>
              <td>{t("numbers.ha")} </td>
              <td>91 598-55-25 </td>
            </tr>
            <tr>
              <th scope="row">15</th>
              <td>15-{t("work.ab")} </td>
              <td>{t("numbers.hb")} </td>
              <td>99 219-66-69 </td>
            </tr>
            <tr>
              <th scope="row">16</th>
              <td>16-{t("work.ab")}</td>
              <td>{t("numbers.hc")}</td>
              <td>
                99 949-58-75 <br /> 99 571-47-25
              </td>
            </tr>
            <tr>
              <th scope="row">17</th>
              <td>17-{t("work.ab")} A</td>
              <td>{t("numbers.hd")}</td>
              <td>99 158-71-75 </td>
            </tr>
            <tr>
              <th scope="row">18</th>
              <td>18-{t("work.ab")} </td>
              <td>{t("numbers.he")} </td>
              <td>99 380-00-18 </td>
            </tr>
            <tr>
              <th scope="row">19</th>
              <td>19-{t("work.ab")} </td>
              <td>{t("numbers.hf")} </td>
              <td> 93 303-86-86</td>
            </tr>
            <tr>
              <th scope="row">20</th>
              <td>20-{t("work.ab")} </td>
              <td>{t("numbers.hg")} </td>
              <td>98 561-17-27</td>
            </tr>
          </MDBTableBody>
        </MDBTable>

        <h3 className="text-center p-3">{t("numbers.hh")}</h3>
        <MDBTable bordered borderColor="primary">
          <MDBTableHead>
            <tr>
              <th scope="col">№</th>
              <th scope="col">{t("numbers.hi")}</th>
              <th scope="col">{t("numbers.hj")} </th>
              <th scope="col">{t("numbers.e")}. </th>
            </tr>
          </MDBTableHead>
          <MDBTableBody>
            <tr>
              <th scope="row">1</th>
              <td>{t("numbers.hk")} </td>
              <td>{t("numbers.hl")}</td>
              <td> 94 579 82 71</td>
            </tr>
            <tr>
              <th scope="row">2</th>
              <td>{t("numbers.hm")} </td>
              <td>{t("numbers.hn")} </td>
              <td>93 306 49 73</td>
            </tr>
            <tr>
              <th scope="row">3</th>
              <td>{t("numbers.ho")} </td>
              <td>{t("numbers.hp")} </td>
              <td> 94 579-54-79</td>
            </tr>
            <tr>
              <th scope="row">4</th>
              <td>{t("numbers.hq")} </td>
              <td>{t("numbers.hr")} </td>
              <td> 90 264-28-98</td>
            </tr>
            <tr>
              <th scope="row">5</th>
              <td>{t("numbers.hs")} </td>
              <td>{t("numbers.ht")} </td>
              <td>94 274 84 26</td>
            </tr>
            <tr>
              <th scope="row">7</th>
              <td>{t("numbers.hu")} </td>
              <td>{t("numbers.hv")} </td>
              <td>97 527 53 57 </td>
            </tr>
            <tr>
              <th scope="row">8</th>
              <td>{t("numbers.hw")} </td>
              <td>{t("numbers.hx")} </td>
              <td> 91 564-60-00 </td>
            </tr>
            <tr>
              <th scope="row">9</th>
              <td>{t("numbers.hy")}</td>
              <td>{t("numbers.hz")} </td>
              <td> 99 674 91 70</td>
            </tr>
            <tr>
              <th scope="row">10</th>
              <td>{t("numbers.ia")} </td>
              <td>{t("numbers.ib")} </td>
              <td> 93 309 00 94</td>
            </tr>
            <tr>
              <th scope="row">11</th>
              <td>{t("numbers.ic")} </td>
              <td>{t("numbers.id")} </td>
              <td> 91 188-24-42</td>
            </tr>
            <tr>
              <th scope="row">12</th>
              <td>{t("numbers.ie")} </td>
              <td>{t("numbers.if")} </td>
              <td> 97 524-14-77</td>
            </tr>
            <tr>
              <th scope="row">13</th>
              <td>{t("numbers.ig")} </td>
              <td>{t("numbers.ih")} </td>
              <td>99 599 68 31 </td>
            </tr>
            <tr>
              <th scope="row">14</th>
              <td>{t("numbers.ii")} </td>
              <td>{t("numbers.ij")} </td>
              <td> 99 206 67 25</td>
            </tr>
            <tr>
              <th scope="row">15</th>
              <td>{t("numbers.ik")} </td>
              <td>{t("numbers.il")}</td>
              <td> 99 196 85 29</td>
            </tr>
            <tr>
              <th scope="row">16</th>
              <td>{t("numbers.im")}</td>
              <td>{t("numbers.in")}</td>
              <td> 93 291 64 83</td>
            </tr>
          </MDBTableBody>
        </MDBTable>
      </div>
    </div>
  );
}
