import React from "react";
import Slider from "../components/Slider";
import Maintop from "../components/Maintop";
import MainSection from "../components/ManiSection";
import Swiper from "../components/Swiper";

const Home = () => {
  return (
    <>
      <Slider />
      <Maintop />
      <MainSection />
      <Swiper />
    </>
  );
};

export default Home;
