import React, { useEffect } from "react";
// import Header from "./Header";
import { AiFillEye } from "react-icons/ai";
import { BiTimeFive } from "react-icons/bi";
import "../Styles/News.css";
// import Footer from "./Footer";
import Aholi from "../Img/aholi.jpg";
import Aholi2 from "../Img/aholi2.jpg";
import Aholi3 from "../Img/aholi3.jpg";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

function Aholifunc() {
  const lang = useSelector((state) => state.store.lang);
  const { t } = useTranslation();
  useEffect(() => {}, [lang]);
  return (
    <div>
      <div className="container saylov">
        <div className="row">
          <h1>{t("aholi.a")}</h1>
          <div className="seen_date d-flex align-items-center">
            <BiTimeFive className="news_icon" />
            <span className="for_icon">
              30 {t("month.jul")} 2021 {t("month.year")}
            </span>
            <AiFillEye className="news_icon" />
            <span className="for_icon">0</span>
          </div>
        </div>
        <div className="row">
          <img src={Aholi} alt="jpg" />
          <p style={{ textAlign: "justify" }}>
            <b>{t("aholi.b")}</b>.
          </p>
          <img src={Aholi2} alt="jpg" />
          <p style={{ textAlign: "justify" }}>{t("aholi.c")}</p>
          <p style={{ textAlign: "justify" }}>{t("aholi.d")}</p>
          <p style={{ textAlign: "justify" }}>{t("aholi.e")}</p>
          <p style={{ textAlign: "justify" }}>{t("aholi.f")}</p>
          <p style={{ textAlign: "justify" }}> </p>
          <img src={Aholi3} alt="jpg" />
          <p style={{ textAlign: "justify" }}> </p>
          <p style={{ textAlign: "justify" }}>
            <b>{t("aholi.g")}.</b>
          </p>
        </div>
      </div>
    </div>
  );
}

export default Aholifunc;
