import React from "react";
import { Link } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import "../../Styles/Footer.css";
import { BiPhone } from "react-icons/bi";
import { Trans, useTranslation } from "react-i18next";
import { FaTelegramPlane } from "react-icons/fa";
import { BsFillEnvelopeFill } from "react-icons/bs";
const Footer = () => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <div className="footer">
        <div className="container">
          <div className="row d-flex justify-content-around align-items-center">
            <div className="col-md-3">
              <h2 className="footer_h2 text-left">{t("footer.a")}</h2>
              <div className="footer_text">
                <p>
                  <BiPhone className="phone"></BiPhone>0(372) 335-43-15
                </p>
                <div className="links d-flex flex-column">
                  <a href="#u">
                    <FaTelegramPlane className="phone"></FaTelegramPlane>
                    @do'stlik.uz
                  </a>
                  <br />
                  <a href="#u">
                    <BsFillEnvelopeFill className="phone"></BsFillEnvelopeFill>
                    dostlik@jizzax.uz
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-4 pt-4">
              <h2 className="footer_h2">{t("footer.b")}</h2>
              <div className="footer_menu d-flex justify-content-between">
                <ul className="d-flex flex-column justify-content-around  pl-0">
                  <li>
                    <Link to="/">{t("footer.c")}</Link>
                  </li>
                  <li>
                    <Link to="/hokimiyat">{t("footer.d")}</Link>
                  </li>
                  <li>
                    <Link to="/">{t("footer.e")}</Link>
                  </li>
                  <li>
                    <Link to="/hokimiyat/mahalla/">{t("footer.f")}</Link>
                  </li>
                </ul>
                <ul className="d-flex flex-column justify-content-around">
                  <li>
                    <Link to="/tashkilotlar">{t("footer.g")}</Link>
                  </li>
                  <li>
                    <Link to="/#talim">{t("footer.h")}</Link>
                  </li>
                  <li>
                    <Link to="/#media">{t("footer.i")}</Link>
                  </li>
                  <li>
                    <Link to="#aloqa">{t("footer.j")}</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-5">
              <iframe
                title="This is a map"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d24261.592157380524!2d68.0215714555689!3d40.52614321707638!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38ad7a305c41f077%3A0x4637a6ba454a195b!2sDo&#39;stlik%2C%20O%60zbekiston!5e0!3m2!1suz!2s!4v1627874339932!5m2!1suz!2s"
                style={{
                  width: "100%",
                  height: "270px",
                  border: 0,
                  allowFullScreen: "",
                  loading: "lazy",
                }}
              ></iframe>
            </div>
          </div>
          <div className="footer_end d-flex justify-content-between">
            <p>{t("footer.k")}</p>
            <p>
              <Trans i18nKey="footer.l">
                <a
                  href="https://teamit.uz/"
                  rel="noreferrer"
                  target={"_blank"}
                  className="teamit-footer text-decoration"
                  style={{ color: "white" }}
                >
                  Team<span style={{ color: "#1EDC9A" }}>it</span>
                </a>
                Coders tomonidan yaratildi 2021
              </Trans>
            </p>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Footer;
