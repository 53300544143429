import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import hokimiyat from "../Img/hokimiyat.jpg";
import Paginations from "./Pagination";

function Hokimiyat(props) {
  const lang = useSelector((state) => state.store.lang);
  const { t } = useTranslation();
  useEffect(() => {}, [lang]);
  return (
    <div className="hokimiyat">
      <h5 className="text-center pt-5">{t("work.u")}</h5>
      <div className="container">
        <div className="row">
          <div class="mycard2 col-md-8 mb-2 mt-3">
            <div class="row no-gutters">
              <div class="col-md-5">
                <img src={hokimiyat} id="img" alt="..." />
              </div>
              <div class="col-md-7">
                <div class="card_body2">
                  <h5 class="card-title">
                    <Link to="/hokimiyat-third-page/arxiv-bolimi">
                      {t("hokim3.a")}
                    </Link>
                  </h5>
                  <p class="card-text">
                    {t("hokim3.b")}
                    ...
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div class="mycard2 col-md-8 mb-2 mt-3">
            <div class="row no-gutters">
              <div class="col-md-5">
                <img src={hokimiyat} id="img" alt="..." />
              </div>
              <div class="col-md-7">
                <div class="card_body2">
                  <h5 class="card-title">
                    <Link to="/hokimiyat-third-page/xalq-talim-mudiri">
                      {t("hokim3.c")}
                    </Link>
                  </h5>
                  <p class="card-text">
                    {t("hokim3.d")}
                    ...
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div class="mycard2 col-md-8 mb-2 mt-3">
            <div class="row no-gutters">
              <div class="col-md-5">
                <img src={hokimiyat} id="img" alt="..." />
              </div>
              <div class="col-md-7">
                <div class="card_body2">
                  <h5 class="card-title">
                    <Link to="/hokimiyat-third-page/maktabgacha-talim">
                      {t("hokim3.e")}
                    </Link>
                  </h5>
                  <p class="card-text">
                    {t("hokim3.f")}
                    ...
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div class="mycard2 col-md-8 mb-2 mt-3">
            <div class="row no-gutters">
              <div class="col-md-5">
                <img src={hokimiyat} id="img" alt="..." />
              </div>
              <div class="col-md-7">
                <div class="card_body2">
                  <h5 class="card-title">
                    <Link to="/hokimiyat-third-page/tibbiyot">
                      {t("hokim3.g")}
                    </Link>
                  </h5>
                  <p class="card-text">
                    {t("hokim3.h")}
                    ...
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div class="mycard2 col-md-8 mb-2 mt-3">
            <div class="row no-gutters">
              <div class="col-md-5">
                <img src={hokimiyat} id="img" alt="..." />
              </div>
              <div class="col-md-7">
                <div class="card_body2">
                  <h5 class="card-title">
                    <Link to="/hokimiyat-third-page/epidemologiya">
                      {t("hokim3.i")}
                    </Link>
                  </h5>
                  <p class="card-text">
                    {t("hokim3.j")}
                    ...
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div class="mycard2 col-md-8 mb-2 mt-3">
            <div class="row no-gutters">
              <div class="col-md-5">
                <img src={hokimiyat} id="img" alt="..." />
              </div>
              <div class="col-md-7">
                <div class="card_body2">
                  <h5 class="card-title">
                    <Link to="/hokimiyat-third-page/jismoniy-tarbiya-va-sport">
                      {t("hokim3.k")}
                    </Link>
                  </h5>
                  <p class="card-text">
                    {t("hokim3.l")}
                    ...
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div class="mycard2 col-md-8 mb-2 mt-3">
            <div class="row no-gutters">
              <div class="col-md-5">
                <img src={hokimiyat} id="img" alt="..." />
              </div>
              <div class="col-md-7">
                <div class="card_body2">
                  <h5 class="card-title">
                    <Link to="/hokimiyat-third-page/madaniya-bolimi-mudiri">
                      {t("hokim3.m")}
                    </Link>
                  </h5>
                  <p class="card-text">
                    {t("hokim3.n")}
                    ...
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div class="mycard2 col-md-8 mb-2 mt-3">
            <div class="row no-gutters">
              <div class="col-md-5">
                <img src={hokimiyat} id="img" alt="..." />
              </div>
              <div class="col-md-7">
                <div class="card_body2">
                  <h5 class="card-title">
                    <Link to="/hokimiyat-third-page/yoshlar-agentligi">
                      {t("hokim3.o")}
                    </Link>
                  </h5>
                  <p class="card-text">
                    {t("hokim3.p")}
                    ...
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div class="mycard2 col-md-8 mb-2 mt-3">
            <div class="row no-gutters">
              <div class="col-md-5">
                <img src={hokimiyat} id="img" alt="..." />
              </div>
              <div class="col-md-7">
                <div class="card_body2">
                  <h5 class="card-title">
                    <Link to="/hokimiyat-third-page/yoshlar-kengashi">
                      {t("hokim3.q")}
                    </Link>
                  </h5>
                  <p class="card-text">
                    {t("hokim3.r")}
                    ...
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div class="mycard2 col-md-8 mb-2 mt-3">
            <div class="row no-gutters">
              <div class="col-md-5">
                <img src={hokimiyat} id="img" alt="..." />
              </div>
              <div class="col-md-7">
                <div class="card_body2">
                  <h5 class="card-title">
                    <Link to="/hokimiyat-third-page/ibn-sino-texnikum-direktori">
                      {t("hokim3.s")}
                    </Link>
                  </h5>
                  <p class="card-text">
                    {t("hokim3.t")}
                    ...
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <Paginations />
      </div>
    </div>
  );
}

export default Hokimiyat;
