import React, { useState, useEffect } from "react";
import YangiliklarCard from "./YangiliklarCard";
import ReactPaginate from "react-paginate";
import { useSelector } from "react-redux";
import Dolzarb from "./Dolzarb";
import "../Styles/Main_top.css";
import axios from "axios";
import "aos/dist/aos.css";
import Aos from "aos";
import { useTranslation } from "react-i18next";
///////////////////////////////////////////
function Main_topp() {
  const API = useSelector((state) => state.store.api);
  const lang = useSelector((state) => state.store.lang);
  const { t } = useTranslation();
  const [loadingNews, setLoadingNews] = useState(false);
  const [loadingNewsD, setLoadingNewsD] = useState(false);
  const [post, setPost] = useState([]);

  const [currentPages, setCurrentPages] = useState(1);
  const [allPage, setAllPage] = useState(0);

  const [postDz, setPostDz] = useState([]);

  const handlePageClick = (data) => {
    setCurrentPages(data.selected + 1);
  };
  useEffect(() => {}, [lang]);

  useEffect(() => {
    const getInfo = async () => {
      setLoadingNews(true);
      const { data } = await axios.get(`${API}/news?page=${currentPages}`);
      setPost(data.news);
      setAllPage(data.pages);
      setLoadingNews(false);
    };
    getInfo();

    const getDolzarb = async () => {
      setLoadingNewsD(true);
      const { data } = await axios.get(`${API}/newsdolzarb`);
      setPostDz(data);
      setLoadingNewsD(false);
    };

    getDolzarb();

    Aos.init({ duration: 600 });
  }, [currentPages, API]);
  // console.log("post;;;;", post);
  // console.log("postDZ:::::", postDz);

  return (
    <div className='main_top container' id='news'>
      <div className='row'>
        <div className='col-lg-8 col-md-12'>
          <h1>{t("footer.e")}</h1>
          <div className=' d-flex flex-wrap gap-5'>
            {loadingNews ? (
              <div className='d-flex justify-content-center '>
                <div className='spinner-border m-5' role='status'>
                  <span className='visually-hidden'>Loading...</span>
                </div>
              </div>
            ) : (
              post.map((article, i) => {
                return (
                  <YangiliklarCard
                    key={i}
                    name={
                      article.name ? article.name[`${lang}`] : article.name?.uz
                    }
                    images={article.images}
                    category={
                      article.Category?.name
                        ? article.Category.name[`${lang}`]
                        : article.Category.name?.uz
                    }
                    alt={article.alt}
                    id={article._id}
                  />
                );
              })
            )}

            <div className='m-7 mt-3'>
              <ReactPaginate
                previousLabel='<<'
                nextLabel='>>'
                breakLabel='...'
                // forcePage={currentPages}
                pageCount={allPage}
                renderOnZeroPageCount={null}
                marginPagesDisplayed={1}
                pageRangeDisplayed={3}
                onPageChange={handlePageClick}
                containerClassName={"pagination justify-content-center"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
                activeClassName={"active"}
              />
            </div>
          </div>
        </div>
        <div className='col-lg-4 col-md-12 card_right'>
          <h1>{t("work.aa")}</h1>
          <div className='row'>
            {loadingNewsD ? (
              <div className='d-flex justify-content-center '>
                <div className='spinner-border m-5' role='status'>
                  <span className='visually-hidden'>Loading...</span>
                </div>
              </div>
            ) : (
              postDz.map((article, i) => {
                return (
                  <Dolzarb
                    key={article._id}
                    name={
                      article.name ? article.name[`${lang}`] : article.name?.uz
                    }
                    images={article.images}
                    category={
                      article.Category?.name
                        ? article.Category.name[`${lang}`]
                        : article.Category.name?.uz
                    }
                    alt={article.alt}
                    id={article._id}
                  />
                );
              })
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Main_topp;
