import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import hokimiyat from "../Img/hokimiyat.jpg";
import Paginations from "./Pagination";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
function Hokimiyat(props) {
  const { t } = useTranslation();
  const lang = useSelector((state) => state.store.lang);
  useEffect(() => {}, [lang]);

  const hokimiyat_css_style = {
    textDecoration: "none",
    fontSize: "15px",
    alignItems: "justify",
  };
  const hokimiyat_css_style2 = {
    textDecoration: "none",
    fontSize: "12px",
    color: "#7d7d7d",
  };
  return (
    <div className="container">
      <h5 className="text-center pt-5">
        <Link
          to="#!"
          className="text-decoration-none text-center rang"
          color="#d7d7d7"
        >
          {t("hokimiyat.a")}
        </Link>
      </h5>
      <div className="row">
        <div className="mycard2 col-lg-5 col-md-8 mb-2 mt-5">
          <div className="row no-gutters">
            <div className="col-md-6">
              <img src={hokimiyat} className="img-fluid" id="img" alt="..." />
            </div>
            <div className="col-md-6">
              <div className="card_body2">
                <h5 className="card-title">
                  <Link style={hokimiyat_css_style} to="/hokimiyat/hokim">
                    {t("hokimiyat.b")}
                  </Link>
                </h5>
                <p style={hokimiyat_css_style2} className="card-text">
                  {t("hokimiyat.c")}
                  ...
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="mycard2 col-lg-5 col-md-8 mb-2 mt-5">
          <div className="row no-gutters">
            <div className="col-md-6">
              <img src={hokimiyat} id="img" alt="..." />
            </div>
            <div className="col-md-6">
              <div className="card_body2">
                <h5 className="card-title">
                  <Link style={hokimiyat_css_style} to="/hokimiyat/moliya">
                    {t("hokimiyat.d")}
                  </Link>
                </h5>
                <p style={hokimiyat_css_style2} className="card-text">
                  {t("hokimiyat.e")}
                  ...
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="mycard2 col-lg-5 col-md-8 mb-2 mt-3">
          <div className="row no-gutters">
            <div className="col-md-6">
              <img src={hokimiyat} id="img" alt="..." />
            </div>
            <div className="col-md-6">
              <div className="card_body2">
                <h5 className="card-title">
                  <Link style={hokimiyat_css_style} to="/hokimiyat/sanoat">
                    {t("hokimiyat.f")}
                  </Link>
                </h5>
                <p style={hokimiyat_css_style2} className="card-text">
                  {t("hokimiyat.g")}
                  ...
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="mycard2 col-lg-5 col-md-8 mb-2 mt-3">
          <div className="row no-gutters">
            <div className="col-md-6">
              <img src={hokimiyat} id="img" alt="..." />
            </div>
            <div className="col-md-6">
              <div className="card_body2">
                <h5 className="card-title">
                  <Link style={hokimiyat_css_style} to="/hokimiyat/savdo">
                    {t("hokimiyat.h")}
                  </Link>
                </h5>
                <p style={hokimiyat_css_style2} className="card-text">
                  {t("hokimiyat.i")}
                  ...
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="mycard2 col-lg-5 col-md-8 mb-2 mt-3">
          <div className="row no-gutters">
            <div className="col-md-6">
              <img src={hokimiyat} id="img" alt="..." />
            </div>
            <div className="col-md-6">
              <div className="card_body2">
                <h5 className="card-title">
                  <Link style={hokimiyat_css_style} to="/hokimiyat/yoshlar">
                    {t("hokimiyat.j")}
                  </Link>
                </h5>
                <p style={hokimiyat_css_style2} className="card-text">
                  {t("hokimiyat.k")}
                  ...
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="mycard2 col-lg-5 col-md-8 mb-2 mt-3">
          <div className="row no-gutters">
            <div className="col-md-6">
              <img src={hokimiyat} id="img" alt="..." />
            </div>
            <div className="col-md-6">
              <div className="card_body2">
                <h5 className="card-title">
                  <Link style={hokimiyat_css_style} to="/hokimiyat/suv">
                    {t("hokimiyat.l")}
                  </Link>
                </h5>
                <p style={hokimiyat_css_style2} className="card-text">
                  {t("hokimiyat.m")}
                  ...
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="mycard2 col-lg-5 col-md-8 mb-2 mt-3">
          <div className="row no-gutters">
            <div className="col-md-6">
              <img src={hokimiyat} id="img" alt="..." />
            </div>
            <div className="col-md-6">
              <div className="card_body2">
                <h5 className="card-title">
                  <Link style={hokimiyat_css_style} to="/hokimiyat/mahalla">
                    {t("hokimiyat.n")}
                  </Link>
                </h5>
                <p style={hokimiyat_css_style2} className="card-text">
                  {t("hokimiyat.o")}
                  ...
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="mycard2 col-lg-5 col-md-8 mb-2 mt-3">
          <div className="row no-gutters">
            <div className="col-md-6">
              <img src={hokimiyat} id="img" alt="..." />
            </div>
            <div className="col-md-6">
              <div className="card_body2">
                <h5 className="card-title">
                  <Link
                    style={hokimiyat_css_style}
                    to="/hokimiyat/moliya-mudiri"
                  >
                    {t("hokimiyat.p")}
                  </Link>
                </h5>
                <p style={hokimiyat_css_style2} className="card-text">
                  {t("hokimiyat.q")}
                  ...
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="mycard2 col-lg-5 col-md-8 mb-2 mt-3">
          <div className="row no-gutters">
            <div className="col-md-6">
              <img src={hokimiyat} id="img" alt="..." />
            </div>
            <div className="col-md-6">
              <div className="card_body2">
                <h5 className="card-title">
                  <Link
                    style={hokimiyat_css_style}
                    to="/hokimiyat/bandlikka-qarshi-kurashish"
                  >
                    {t("hokimiyat.r")}
                  </Link>
                </h5>
                <p style={hokimiyat_css_style2} className="card-text">
                  {t("hokimiyat.s")}
                  ...
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="mycard2 col-lg-5 col-md-8 mb-2 mt-3">
          <div className="row no-gutters">
            <div className="col-md-6">
              <img src={hokimiyat} id="img" alt="..." />
            </div>
            <div className="col-md-6">
              <div className="card_body2">
                <h5 className="card-title">
                  <Link style={hokimiyat_css_style} to="/hokimiyat/toza-hudud">
                    {t("hokimiyat.t")}
                  </Link>
                </h5>
                <p style={hokimiyat_css_style2} className="card-text">
                  {t("hokimiyat.u")}
                  ...
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Paginations />
    </div>
    /* </div> */
  );
}

export default Hokimiyat;
