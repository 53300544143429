import React, { useEffect } from "react";
import { AiFillEye } from "react-icons/ai";
import { BiTimeFive } from "react-icons/bi";
import "../Styles/News.css";
import xiyobon from "../Img/xiyobon.jpg";
import xiyobon2 from "../Img/xiyobon2.jpg";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

function Xiyobon() {
  const lang = useSelector((state) => state.store.lang);
  const { t } = useTranslation();
  useEffect(() => {}, [lang]);
  return (
    <div>
      <div className="container saylov">
        <div className="row">
          <h1>{t("xiyobon.a")}</h1>
          <div className="seen_date d-flex align-items-center">
            <BiTimeFive className="news_icon" />
            <span className="for_icon">
              30 {t("month.jul")} 2021 {t("month.year")}
            </span>
            <AiFillEye className="news_icon" />
            <span className="for_icon">0</span>
          </div>
        </div>
        <div className="row">
          <img src={xiyobon} alt="xiyobon" />
          <p style={{ textAlign: "justify" }}>
            <b>{t("xiyobon.b")}.</b>.
          </p>
          <p style={{ textAlign: "justify" }}> {t("xiyobon.c")}.</p>
          <img src={xiyobon2} alt="xiyobon.jpg" />
          <p style={{ textAlign: "justify" }}>{t("xiyobon.d")} .</p>
          <p style={{ textAlign: "justify" }}>{t("xiyobon.e")} .</p>
          <p style={{ textAlign: "justify" }}>
            <b>{t("xiyobon.f")}</b>
          </p>
        </div>
      </div>
    </div>
  );
}

export default Xiyobon;
