import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import "../Styles/Tashkilotlar.css";

function Tashkilotlar() {
  const lang = useSelector((state) => state.store.lang);
  const { t } = useTranslation();
  useEffect(() => {}, [lang]);
  return (
    <div className="tashkilotlar">
      <div className="container mt-3">
        <div className="row">
          <h1>{t("tashkilotlar.b")}</h1>
        </div>
        <div className="tashkilot row d-flex justify-content-between">
          <div className="col-md-12">
            <h4 className="text-center">
              <a href="https://www.goldenpages.uz/uz/company/?Id=80130&CatId=">
                {t("tashkilotlar.c")}
              </a>{" "}
            </h4>
            <p>
              <b>{t("tashkilotlar.d")}:</b>
              {t("tashkilotlar.o")}
            </p>
            <p>
              <b> {t("tashkilotlar.h")}: </b>72 335 49 42
            </p>
            <p>
              <b>{t("tashkilotlar.g")}: </b>
              {t("tashkilotlar.p")}
            </p>
            <p>
              <b>{t("tashkilotlar.a")}: </b>99 569 87 87
            </p>
            <p>
              <b>{t("tashkilotlar.f")}: </b>
              {t("tashkilotlar.q")}
            </p>
            <p>
              <b>{t("tashkilotlar.a")}: 99 074 66 39</b>
            </p>
          </div>
        </div>
        <div className="tashkilot row d-flex justify-content-between">
          <div className="col-md-12">
            <h4 className="text-center">
              <a href="https://www.goldenpages.uz/uz/company/?Id=80130&CatId=">
                {t("tashkilotlar.r")}
              </a>{" "}
            </h4>
            <p>
              <b>{t("tashkilotlar.d")}:</b>
              {t("tashkilotlar.s")}
            </p>
            <p>
              <b>{t("tashkilotlar.g")}: </b>
              {t("tashkilotlar.t")}
            </p>
            <p>
              <b>{t("tashkilotlar.a")}: </b>93 940 01 10
            </p>
            <p>
              <b>{t("tashkilotlar.f")}: </b>
              {t("tashkilotlar.u")}
            </p>
            <p>
              <b>{t("tashkilotlar.a")}:</b>
            </p>
          </div>
        </div>
        <div className="tashkilot row d-flex justify-content-between">
          <div className="col-md-12">
            <h4 className="text-center">
              <a href="https://www.goldenpages.uz/uz/company/?Id=80130&CatId=">
                {t("tashkilotlar.v")}{" "}
              </a>
            </h4>
            <p>
              <b>{t("tashkilotlar.m")}: </b>
              {t("tashkilotlar.w")}
            </p>
            <p>
              <b>{t("tashkilotlar.a")}: </b>99 599 68 31
            </p>
            <p>
              <b>{t("tashkilotlar.x")}: </b>
              {t("tashkilotlar.y")}
            </p>
            <p>
              <b>{t("tashkilotlar.a")}: 97 524 14 77</b>
            </p>
          </div>
        </div>
        <div className="tashkilot row d-flex justify-content-between">
          <div className="col-md-12">
            <h4 className="text-center">
              <a href="https://www.goldenpages.uz/uz/company/?Id=80130&CatId=">
                {t("tashkilotlar.z")}
              </a>
            </h4>
            <p>
              <b>{t("tashkilotlar.d")}:</b>
              {t("tashkilotlar.aa")}
            </p>
            <p>
              <b>{t("tashkilotlar.h")}: </b>72 335 44 11{" "}
            </p>
            <p>
              <b>{t("tashkilotlar.i")}: </b>
              {t("tashkilotlar.ab")}
            </p>
            <p>
              <b>{t("tashkilotlar.a")}: </b>99 5556 63 05
            </p>
            <p>
              <b>{t("tashkilotlar.j")}: </b>
              {t("tashkilotlar.ac")}
            </p>
            <p>
              <b>{t("tashkilotlar.a")}: 91 565 62 88</b>
            </p>
          </div>
        </div>
        <div className="tashkilot row d-flex justify-content-between">
          <div className="col-md-12">
            <h4 className="text-center">
              <a href="https://www.goldenpages.uz/uz/company/?Id=80130&CatId=">
                {t("tashkilotlar.ad")}
              </a>
            </h4>
            <p>
              <b>{t("tashkilotlar.e")}: </b>
              {t("tashkilotlar.ae")}
            </p>
            <p>
              <b>{t("tashkilotlar.a")}: </b>99 339 33 88, 94 025 33 88
            </p>
            <p>
              <b>{t("tashkilotlar.j")}: </b>
              {t("tashkilotlar.af")}
            </p>
            <p>
              <b>{t("tashkilotlar.a")}: 94 575 30 35</b>
            </p>
          </div>
        </div>
        <div className="tashkilot row d-flex justify-content-between">
          <div className="col-md-12">
            <h4 className="text-center">
              <a href="https://www.goldenpages.uz/uz/company/?Id=80130&CatId=">
                {t("tashkilotlar.ag")}
              </a>
            </h4>
            <p>
              <b>{t("tashkilotlar.e")}:</b>
              {t("tashkilotlar.ah")}
            </p>
            <p>
              <b>{t("tashkilotlar.a")}: </b>91 208 17 87, 91 207 17 87{" "}
            </p>
          </div>
        </div>
        <div className="tashkilot row d-flex justify-content-between">
          <div className="col-md-12">
            <h4 className="text-center">
              <a href="https://www.goldenpages.uz/uz/company/?Id=80130&CatId=">
                {t("tashkilotlar.ai")}
              </a>
            </h4>
            <p>
              <b>{t("tashkilotlar.d")}:</b>
              {t("tashkilotlar.aj")}
            </p>
            <p>
              <b>{t("tashkilotlar.n")}: </b>
              {t("tashkilotlar.ak")}
            </p>
            <p>
              <b>{t("tashkilotlar.a")}: </b>99 557 55 90
            </p>
            <p>
              <b>{t("tashkilotlar.al")}: </b>
              {t("tashkilotlar.am")}
            </p>
            <p>
              <b>{t("tashkilotlar.a")}: 94 197 83 83</b>
            </p>
          </div>
        </div>
        <div className="tashkilot row d-flex justify-content-between">
          <div className="col-md-12">
            <h4 className="text-center">
              <a href="https://www.goldenpages.uz/uz/company/?Id=80130&CatId=">
                {t("tashkilotlar.an")}
              </a>
            </h4>
            <p>
              <b>{t("tashkilotlar.e")}: </b>
              {t("tashkilotlar.ao")}
            </p>
            <p>
              <b>{t("tashkilotlar.a")}: </b>97 025 32 16
            </p>
            <p>
              <b>{t("tashkilotlar.ap")}: </b>
              {t("tashkilotlar.aq")}
            </p>
            <p>
              <b>{t("tashkilotlar.a")}: 88 437 95 95</b>
            </p>
          </div>
        </div>
        <div className="tashkilot row d-flex justify-content-between">
          <div className="col-md-12">
            <h4 className="text-center">
              <a href="https://www.goldenpages.uz/uz/company/?Id=80130&CatId=">
                {t("tashkilotlar.ar")}
              </a>
            </h4>
            <p>
              <b>{t("tashkilotlar.k")}: </b>
              {t("tashkilotlar.as")}
            </p>
            <p>
              <b>{t("tashkilotlar.a")}: </b>93 295 88 84
            </p>
            <p>
              <b>{t("tashkilotlar.l")}: </b>
              {t("tashkilotlar.at")}
            </p>
            <p>
              <b>{t("tashkilotlar.a")}:94 349 00 15</b>
            </p>
          </div>
        </div>
        <div className="tashkilot row d-flex justify-content-between">
          <div className="col-md-12">
            <h4 className="text-center">
              <a href="https://www.goldenpages.uz/uz/company/?Id=80130&CatId=">
                {t("tashkilotlar.au")}
              </a>
            </h4>
            <p>
              <b>{t("tashkilotlar.av")}: </b>
              {t("tashkilotlar.aw")}
            </p>
            <p>
              <b>{t("tashkilotlar.a")}: </b>99 333 63 13
            </p>
            <p>
              <b>{t("tashkilotlar.k")}: </b>
              {t("tashkilotlar.ax")}
            </p>
            <p>
              <b>{t("tashkilotlar.a")}: 99 550 07 34</b>
            </p>
          </div>
        </div>
        <div className="tashkilot row d-flex justify-content-between">
          <div className="col-md-12">
            <h4 className="text-center">
              <a href="https://www.goldenpages.uz/uz/company/?Id=80130&CatId=">
                {t("tashkilotlar.ay")}
              </a>
            </h4>
            <p>
              <b>{t("tashkilotlar.a")}:</b>72 335 10 00
            </p>
            <p>
              <b>{t("tashkilotlar.h")}: </b>72 335 44 11{" "}
            </p>
            <p>
              <b>{t("tashkilotlar.m")}: </b>
              {t("tashkilotlar.az")}
            </p>
            <p>
              <b>{t("tashkilotlar.a")}: </b>94 162 52 14
            </p>
            <p>
              <b>{t("tashkilotlar.l")}: </b>
              {t("tashkilotlar.ba")}
            </p>
            <p>
              <b>{t("tashkilotlar.a")}: 93 291 07 80</b>
            </p>
          </div>
        </div>
        <div className="tashkilot row d-flex justify-content-between">
          <div className="col-md-12">
            <h4 className="text-center">
              <a href="https://www.goldenpages.uz/uz/company/?Id=80130&CatId=">
                {t("tashkilotlar.bb")}
              </a>
            </h4>
            <p>
              <b>{t("tashkilotlar.e")}:</b>
              {t("tashkilotlar.bc")}
            </p>
            <p>
              <b>{t("tashkilotlar.a")}: </b>99 078 96 97
            </p>
            <p>
              <b>{t("tashkilotlar.bd")}: </b>
              {t("tashkilotlar.be")}
            </p>
            <p>
              <b>{t("tashkilotlar.a")}: 33 700 20 70</b>
            </p>
          </div>
        </div>
        <div className="tashkilot row d-flex justify-content-between">
          <div className="col-md-12">
            <h4 className="text-center">
              <a href="https://www.goldenpages.uz/uz/company/?Id=80130&CatId=">
                {t("tashkilotlar.bf")}
              </a>
            </h4>
            <p>
              <b>{t("tashkilotlar.n")}: </b>
              {t("tashkilotlar.bg")}
            </p>
            <p>
              <b>{t("tashkilotlar.a")}: </b>94 343 31 36
            </p>
            <p>
              <b>{t("tashkilotlar.bh")}: </b>
              {t("tashkilotlar.bi")}
            </p>
            <p>
              <b>{t("tashkilotlar.a")}: 94 575 17 79</b>
            </p>
          </div>
        </div>
        <div className="tashkilot row d-flex justify-content-between">
          <div className="col-md-12">
            <h4 className="text-center">
              <a href="https://www.goldenpages.uz/uz/company/?Id=80130&CatId=">
                {" "}
                {t("tashkilotlar.bj")}
              </a>
            </h4>
            <p>
              <b>{t("tashkilotlar.e")}: </b>
              {t("tashkilotlar.bk")}
            </p>
            <p>
              <b>{t("tashkilotlar.a")}: </b>93 829 88 85
            </p>
            <p>
              <b>{t("tashkilotlar.bl")}: </b>
              {t("tashkilotlar.bm")}
            </p>
            <p>
              <b>{t("tashkilotlar.a")}: 93 308 29 79</b>
            </p>
          </div>
        </div>
        <div className="tashkilot row d-flex justify-content-between">
          <div className="col-md-12">
            <h4 className="text-center">
              <a href="https://www.goldenpages.uz/uz/company/?Id=80130&CatId=">
                {t("tashkilotlar.bn")}{" "}
              </a>
            </h4>
            <p>
              <b>{t("tashkilotlar.e")}: </b>
              {t("tashkilotlar.bo")}
            </p>
            <p>
              <b>{t("tashkilotlar.a")}: </b>94 192 73 37
            </p>
          </div>
        </div>
        <div className="tashkilot row d-flex justify-content-between">
          <div className="col-md-12">
            <h4 className="text-center">
              <a href="https://www.goldenpages.uz/uz/company/?Id=80130&CatId=">
                {t("tashkilotlar.bp")}{" "}
              </a>
            </h4>
            <p>
              <b>{t("tashkilotlar.d")}:</b>
              {t("tashkilotlar.bq")}
            </p>
            <p>
              <b>{t("tashkilotlar.e")}: </b>
              {t("tashkilotlar.br")}
            </p>
            <p>
              <b>{t("tashkilotlar.a")}: </b>91 59 83 68
            </p>
            <p>
              <b>{t("tashkilotlar.k")}i: </b>
              {t("tashkilotlar.bs")}
            </p>
            <p>
              <b>{t("tashkilotlar.a")}: 94 574 23 01</b>
            </p>
          </div>
        </div>
        <div className="tashkilot row d-flex justify-content-between">
          <div className="col-md-12">
            <h4 className="text-center">
              <a href="https://www.goldenpages.uz/uz/company/?Id=80130&CatId=">
                {t("tashkilotlar.bt")}
              </a>
            </h4>
            <p>
              <b>Ish tel:</b> 7 335 47 23
            </p>
            <p>
              <b>{t("tashkilotlar.d")}:</b>
              {t("tashkilotlar.bu")}
            </p>
            <p>
              <b>{t("tashkilotlar.e")}: </b>
              {t("tashkilotlar.bv")}
            </p>
            <p>
              <b>{t("tashkilotlar.a")}: </b>94 576 18 07
            </p>
            <p>
              <b>{t("tashkilotlar.m")}: </b>
              {t("tashkilotlar.bw")}
            </p>
            <p>
              <b>{t("tashkilotlar.a")}: 99 740 15 62</b>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Tashkilotlar;
