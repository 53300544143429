import React, { useEffect } from "react";
import "../Styles/News.css";
import { ListGroup, Row, Tab, Col } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

function Mahalla() {
  const lang = useSelector((state) => state.store.lang);
  const { t } = useTranslation();
  useEffect(() => {}, [lang]);
  return (
    <div>
      <div className="container mahhalla">
        <div className="row">
          <h1>{t("footer.f")}</h1>
          <div className="col-lg-12">
            <Tab.Container
              id="list-group-tabs-example"
              defaultActiveKey="#link1"
            >
              <Row>
                <Col sm={4}>
                  <ListGroup>
                    <ListGroup.Item action href="#HOKIM">
                      {t("mahalla.a")}
                    </ListGroup.Item>
                    <ListGroup.Item action href="#NAVOIY">
                      Navoiy MFY
                    </ListGroup.Item>
                    <ListGroup.Item action href="#MANAS">
                      Manas MFY
                    </ListGroup.Item>
                    <ListGroup.Item action href="#NAVROZ">
                      Navro'z MFY
                    </ListGroup.Item>
                    <ListGroup.Item action href="#QAXRAMON">
                      Qaxramon MFY
                    </ListGroup.Item>
                    <ListGroup.Item action href="#MEVAZOR">
                      Mevazor MFY
                    </ListGroup.Item>
                    <ListGroup.Item action href="#CHINOBOD">
                      Chinobod MFY
                    </ListGroup.Item>
                    <ListGroup.Item action href="#BUNYODKOR">
                      Bunyodkor MFY
                    </ListGroup.Item>
                    <ListGroup.Item action href="#GULOM">
                      G'.G'ulom MFY
                    </ListGroup.Item>
                    <ListGroup.Item action href="#SARITEPA">
                      Saritepa MFY
                    </ListGroup.Item>
                    <ListGroup.Item action href="#BOGZOR">
                      Bog'zor MFY
                    </ListGroup.Item>
                    <ListGroup.Item action href="#OLTINVODIY">
                      Oltin Vodiy MFY
                    </ListGroup.Item>
                    <ListGroup.Item action href="#SANOATCHILAR">
                      Sanoatchilar MFY
                    </ListGroup.Item>
                  </ListGroup>
                </Col>
                <Col sm={8}>
                  <Tab.Content>
                    <Tab.Pane eventKey="#HOKIM">
                      <h1>
                        <p>{t("mahalla.b")}</p>
                        Oqilov Jahongir Qurbonovich
                      </h1>
                      <h3>
                        <p>{t("work.w")}</p>12.05.1989
                      </h3>
                      <h3>
                        <p>{t("work.x")}</p>Jizzax viloyati, Arnasoy tumani
                      </h3>
                      <h3>
                        <p>{t("footer.a")}</p>+998(90)264-44-11
                      </h3>
                      <h3>+998(90)516-00-66</h3>
                    </Tab.Pane>
                    <Tab.Pane eventKey="#NAVOIY">
                      <h1>
                        <p>{t("work.v")}</p>Isroilova Zamira Jonuzoqovna
                      </h1>
                      <h3>
                        <p>{t("work.w")}</p>02.01.1957
                      </h3>
                      <h3>
                        <p>{t("work.x")}</p>Jizzax viloyati, G'allarol tumani
                      </h3>
                      <h3>
                        <p>{t("footer.a")}</p>+998(97)774-01-93
                      </h3>
                    </Tab.Pane>
                    <Tab.Pane eventKey="#MANAS">
                      <h1>
                        <p>{t("work.v")}</p>Kenjayev Abdujalil Qodirovich
                      </h1>
                      <h3>
                        <p>{t("work.w")}</p>15.06.1956
                      </h3>
                      <h3>
                        <p>{t("work.x")}</p>Jizzax viloyati, Zomin tumani
                      </h3>
                      <h3>
                        <p>{t("footer.a")}</p>+998(93)650-45-56
                      </h3>
                    </Tab.Pane>
                    <Tab.Pane eventKey="#NAVROZ">
                      <h1>
                        <p>{t("work.v")}</p>To'rayev G'ayrat Yusupovich
                      </h1>
                      <h3>
                        <p>{t("work.w")}</p>14.04.1988
                      </h3>
                      <h3>
                        <p>{t("work.x")}</p>Jizzax viloyati, Do'stlik tumani
                      </h3>
                      <h3>
                        <p>{t("footer.a")}</p>+998(94)342-70-86
                      </h3>
                    </Tab.Pane>
                    <Tab.Pane eventKey="#QAXRAMON">
                      <h1>
                        <p>{t("work.v")}</p>Mullayeva Muazzam Qo'ysinboyevna
                      </h1>
                      <h3>
                        <p>{t("work.w")}</p>02.01.1968
                      </h3>
                      <h3>
                        <p>{t("work.x")}</p>Andijon viloyati, Marhamat tumani
                      </h3>
                      <h3>
                        <p>{t("footer.a")}</p>+998(94)197-05-67
                      </h3>
                    </Tab.Pane>
                    <Tab.Pane eventKey="#MEVAZOR">
                      <h1>
                        <p>{t("work.v")}</p>Musayeva Robiya Omonovna
                      </h1>
                      <h3>
                        <p>{t("work.w")}</p>08.02.1957
                      </h3>
                      <h3>
                        <p>{t("work.x")}</p>Samarqand viloyati, Pastarg'om
                        tumani
                      </h3>
                      <h3>
                        <p>{t("footer.a")}</p>+998(99)426-02-57
                      </h3>
                    </Tab.Pane>
                    <Tab.Pane eventKey="#CHINOBOD">
                      <h1>
                        <p>{t("work.v")}</p>Qarshiboyeva Muhayyo Abduvali qizi
                      </h1>
                      <h3>
                        <p>{t("work.w")}</p>30.01.1993
                      </h3>
                      <h3>
                        <p>{t("work.x")}</p>Sirdaryo viloyati, Sardoba tumani
                      </h3>
                      <h3>
                        <p>{t("footer.a")}</p>+998(99)157-02-56
                      </h3>
                    </Tab.Pane>
                    <Tab.Pane eventKey="#BUNYODKOR">
                      <h1>
                        <p>{t("work.v")}</p>Bolqiyeva Gulnor Mamasharipovna
                      </h1>
                      <h3>
                        <p>{t("work.w")}</p>19.11.1964
                      </h3>
                      <h3>
                        <p>{t("work.x")}</p>Samarqand viloyati, Bulung'ur tumani
                      </h3>
                      <h3>
                        <p>{t("footer.a")}</p>+998(33)519-11-64
                      </h3>
                    </Tab.Pane>
                    <Tab.Pane eventKey="#GULOM">
                      <h1>
                        <p>{t("work.v")}</p>Rahmatova Gulnora Meliyevna
                      </h1>
                      <h3>
                        <p>{t("work.w")}</p>15.01.1962
                      </h3>
                      <h3>
                        <p>{t("work.x")}</p>Samarqand viloyati, Oqdaryo tumani
                      </h3>
                      <h3>
                        <p>{t("footer.a")}</p>+998(90)311-29-35
                      </h3>
                    </Tab.Pane>
                    <Tab.Pane eventKey="#SARITEPA">
                      <h1>
                        <p>{t("work.v")}</p>Botirova Norxol Berdikulovna
                      </h1>
                      <h3>
                        <p>{t("work.w")}</p>25.09.1960
                      </h3>
                      <h3>
                        <p>{t("work.x")}</p>Samarqand viloyati, Qo'shrabot
                        tumani
                      </h3>
                      <h3>
                        <p>{t("footer.a")}</p>+998(94)572-54-70
                      </h3>
                    </Tab.Pane>
                    <Tab.Pane eventKey="#BOGZOR">
                      <h1>
                        <p>{t("work.v")}</p>Mirzayeva Nasiba Murodovna
                      </h1>
                      <h3>
                        <p>{t("work.w")}</p>15.05.1957
                      </h3>
                      <h3>
                        <p>{t("work.x")}</p>Chimkent viloyati, Ilich tumani
                      </h3>
                      <h3>
                        <p>{t("footer.a")}</p>+998(91)594-32-75
                      </h3>
                    </Tab.Pane>
                    <Tab.Pane eventKey="#OLTINVODIY">
                      <h1>
                        <p>{t("work.v")}</p>Abdujalilova Gulnora Sa'dullayevna
                      </h1>
                      <h3>
                        <p>{t("work.w")}</p>05.06.1960
                      </h3>
                      <h3>
                        <p>{t("work.x")}</p>Jizzax viloyati, Do'stlik tumani
                      </h3>
                      <h3>
                        <p>{t("footer.a")}</p>+998(99)508-38-60
                      </h3>
                    </Tab.Pane>
                    <Tab.Pane eventKey="#SANOATCHILAR">
                      <h1>
                        <p>{t("work.v")}</p>Lapasova Zuhra Shukurovna
                      </h1>
                      <h3>
                        <p>{t("work.w")}</p>09.01.1965
                      </h3>
                      <h3>
                        <p>{t("work.x")}</p>Jizzax viloyati, Mirzacho'l tumani
                      </h3>
                      <h3>
                        <p>{t("footer.a")}</p>+998(94)194-09-65
                      </h3>
                    </Tab.Pane>
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Mahalla;
