import SwiperCore, { Pagination } from "swiper/core";
import { Swiper, SwiperSlide } from "swiper/react";
import swiper6 from "../Img/swiper6.png";
import swiper5 from "../Img/swiper5.png";
import lex from "../Img/Lex.png";
import "../Styles/Main.css";
import React from "react";
import Aos from "aos";
import "swiper/css";

import { useTranslation } from "react-i18next";

SwiperCore.use([Pagination]);

export default function App() {
  const { t } = useTranslation();
  Aos.init({ duration: 600 });
  return (
    <div className="swiper">
      <div className="container-fluid">
        <div className="row" data-aos="fade-down" data-aos-offset="400">
          <Swiper
            slidesPerView={3}
            spaceBetween={60}
            freeMode={true}
            pagination={{
              clickable: true,
            }}
            className="mySwiper d-flex align-items-center"
          >
            <SwiperSlide className="col-md-12">
              <a target="_blank" rel="noreferrer" href="http://pm.gov.uz/uz">
                <div className="img_bx text-center">
                  <img
                    src="https://senat.uz/_nuxt/img/logo.40a592a.png"
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <div className="img_content text-center">
                  <p>{t("swiper.a")}</p>
                </div>
              </a>
            </SwiperSlide>
            <SwiperSlide>
              <a href="http://dostlik.jizzax.uz/">
                <div className="img_bx text-center mt-xs-5 mt-sm-4 mt-md-0">
                  <img
                    src="https://senat.uz/media/post/images/logo.png"
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <div className="img_content text-center">
                  <p>{t("swiper.b")}</p>
                </div>
              </a>
            </SwiperSlide>
            <SwiperSlide>
              <a href="https://parliament.gov.uz/uz/">
                <div className="img_bx text-center">
                  <img
                    src="https://senat.uz/media/post/images/logo.png"
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <div className="img_content text-center">
                  <p>{t("swiper.c")}</p>
                </div>
              </a>
            </SwiperSlide>
            <SwiperSlide>
              <a href="http://ek.uz/uz/">
                <div className="img_bx text-center mt-3">
                  <img
                    src="https://senat.uz/media/post/images/logo.png"
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <div className="img_content text-center">
                  <p>{t("swiper.d")}</p>
                </div>
              </a>
            </SwiperSlide>
            <SwiperSlide>
              <a href="http://www.lex.uz/">
                <div className="img_bx text-center mt-3">
                  <img src={lex} className="img-fluid" alt="" />
                </div>
                <div className="img_content text-center">
                  <p>{t("swiper.e")}</p>
                </div>
              </a>
            </SwiperSlide>
            <SwiperSlide>
              <a href="http://id.gov.uz/">
                <div className="img_bx text-center">
                  <img src={swiper5} className="img-fluid" alt="" />
                </div>
                <div className="img_content text-center">
                  <p>{t("swiper.f")}</p>
                </div>
              </a>
            </SwiperSlide>
            <SwiperSlide>
              <a href="http://strategy.gov.uz/uz">
                <div className="img_bx text-center mb-2">
                  <img src={swiper6} className="img-fluid" alt="" />
                </div>
                <div className="img_content text-center">
                  <p>{t("swiper.g")}</p>
                </div>
              </a>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </div>
  );
}
