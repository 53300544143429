import { createSlice } from "@reduxjs/toolkit";
// const i = JSON.parse(localStorage.getItem("i18nextLng"));
const Slices = createSlice({
  name: "lang",
  initialState: {
    api: "https://api.dustlik.uz",
    lang: "uz",
  },
  reducers: {
    updateLang: (state, action) => {
      state.lang = action.payload;
    },
  },
});

export const { updateLang } = Slices.actions;
export default Slices.reducer;
