import React from "react";
import ImageGallaryComponent from "./YangiliklarSlider.component";
import { useSelector } from "react-redux";
const Yangiliklar = ({ name, images, fulltext, post_id }) => {
  const { api, lang } = useSelector((state) => state.store);

  return (
    <div>
      {images ? (
        <ImageGallaryComponent
          id={post_id}
          key={post_id}
          images={images}
          name={name ? name[`${lang}`] : name?.uz}
          api={api}
        />
      ) : (
        ""
      )}

      <h1
        className='news-text-style'
        style={{ fontSize: "24px", margin: "10px" }}
      >
        {name ? name[`${lang}`] : name?.uz}
      </h1>
      <div
        className='news-fulltext-style'
        style={{ fontSize: "16px" }}
        dangerouslySetInnerHTML={{
          __html: fulltext ? fulltext[`${lang}`] : fulltext?.uz,
        }}
      ></div>
    </div>
  );
};

export default Yangiliklar;
