import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import hokimiyat from "../Img/hokimiyat.jpg";
import Paginations from "./Pagination";

function Hokimiyat(props) {
  const lang = useSelector((state) => state.store.lang);
  const { t } = useTranslation();
  useEffect(() => {}, [lang]);
  return (
    <div className="hokimiyat">
      <h5 className="text-center pt-5">{t("work.u")}</h5>
      <div className="container">
        <div className="row">
          <div className="mycard2 col-md-8 mb-2 mt-3">
            <div className="row no-gutters">
              <div className="col-md-5">
                <img src={hokimiyat} id="img" alt="..." />
              </div>
              <div className="col-md-7">
                <div className="card_body2">
                  <h5 className="card-title">
                    <Link to="/hokimiyat-fourth-page/dostlik-hayoti-muhariri">
                      {t("hokim4.a")}
                    </Link>
                  </h5>
                  <p className="card-text">{t("hokim4.b")}...</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="mycard2 col-md-8 mb-2 mt-3">
            <div className="row no-gutters">
              <div className="col-md-5">
                <img src={hokimiyat} id="img" alt="..." />
              </div>
              <div className="col-md-7">
                <div className="card_body2">
                  <h5 className="card-title">
                    <Link to="/hokimiyat-fourth-page/manaviyat-bolimi-boshligi">
                      {t("hokim4.c")}
                    </Link>
                  </h5>
                  <p className="card-text">{t("hokim4.d")} ...</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="mycard2 col-md-8 mb-2 mt-3">
            <div className="row no-gutters">
              <div className="col-md-5">
                <img src={hokimiyat} id="img" alt="..." />
              </div>
              <div className="col-md-7">
                <div className="card_body2">
                  <h5 className="card-title">
                    <Link to="/hokimiyat-fourth-page/xalq-talim-mudirasi">
                      {t("hokim4.e")}
                    </Link>
                  </h5>
                  <p className="card-text">{t("hokim4.f")} ...</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="mycard2 col-md-8 mb-2 mt-3">
            <div className="row no-gutters">
              <div className="col-md-5">
                <img src={hokimiyat} id="img" alt="..." />
              </div>
              <div className="col-md-7">
                <div className="card_body2">
                  <h5 className="card-title">
                    <Link to="/hokimiyat-fourth-page/kadastr">
                      {t("hokim4.g")}
                    </Link>
                  </h5>
                  <p className="card-text">
                    {t("hokim4.h")}
                    ...
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="mycard2 col-md-8 mb-2 mt-3">
            <div className="row no-gutters">
              <div className="col-md-5">
                <img src={hokimiyat} id="img" alt="..." />
              </div>
              <div className="col-md-7">
                <div className="card_body2">
                  <h5 className="card-title">
                    <Link to="/hokimiyat-fourth-page/elektr-energiya">
                      {t("hokim4.i")}
                    </Link>
                  </h5>
                  <p className="card-text">
                    {t("hokim4.j")}
                    ...
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="mycard2 col-md-8 mb-2 mt-3">
            <div className="row no-gutters">
              <div className="col-md-5">
                <img src={hokimiyat} id="img" alt="..." />
              </div>
              <div className="col-md-7">
                <div className="card_body2">
                  <h5 className="card-title">
                    <Link to="/hokimiyat-fourth-page/tuman-gaz">
                      {t("hokim4.k")}
                    </Link>
                  </h5>
                  <p className="card-text">
                    {t("hokim4.l")}
                    ...
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="mycard2 col-md-8 mb-2 mt-3">
            <div className="row no-gutters">
              <div className="col-md-5">
                <img src={hokimiyat} id="img" alt="..." />
              </div>
              <div className="col-md-7">
                <div className="card_body2">
                  <h5 className="card-title">
                    <Link to="/hokimiyat-fourth-page/suv-taminoti">
                      {t("hokim4.m")}
                    </Link>
                  </h5>
                  <p className="card-text">
                    {t("hokim4.n")}
                    ...
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="mycard2 col-md-8 mb-2 mt-3">
            <div className="row no-gutters">
              <div className="col-md-5">
                <img src={hokimiyat} id="img" alt="..." />
              </div>
              <div className="col-md-7">
                <div className="card_body2">
                  <h5 className="card-title">
                    <Link to="/hokimiyat-fourth-page/obodonlashtirish">
                      {t("hokim4.o")}
                    </Link>
                  </h5>
                  <p className="card-text">
                    {t("hokim4.p")}
                    ...
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="mycard2 col-md-8 mb-2 mt-3">
            <div className="row no-gutters">
              <div className="col-md-5">
                <img src={hokimiyat} id="img" alt="..." />
              </div>
              <div className="col-md-7">
                <div className="card_body2">
                  <h5 className="card-title">
                    <Link to="/hokimiyat-fourth-page/telekamunikatsiya">
                      {t("hokim4.q")}
                    </Link>
                  </h5>
                  <p className="card-text">
                    {t("hokim4.r")}
                    ...
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="mycard2 col-md-8 mb-2 mt-3">
            <div className="row no-gutters">
              <div className="col-md-5">
                <img src={hokimiyat} id="img" alt="..." />
              </div>
              <div className="col-md-7">
                <div className="card_body2">
                  <h5 className="card-title">
                    <Link to="/hokimiyat-fourth-page/yollardan-foydalish">
                      {t("hokim4.s")}
                    </Link>
                  </h5>
                  <p className="card-text">
                    {t("hokim4.t")}
                    ...
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <Paginations />
      </div>
    </div>
  );
}

export default Hokimiyat;
