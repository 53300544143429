import React, { useEffect } from "react";
import { AiFillEye } from "react-icons/ai";
import { BiTimeFive } from "react-icons/bi";
import "../Styles/News.css";
import { Link } from "react-router-dom";

import narbayeva from "../Img/narbayeva.jpg";
import { useSelector } from "react-redux";
import { Trans, useTranslation } from "react-i18next";

function OdamSavdosi() {
  const lang = useSelector((state) => state.store.lang);
  const { t } = useTranslation();
  useEffect(() => {}, [lang]);
  return (
    <div>
      <div className="container saylov">
        <div className="row">
          <h1>{t("odamsavdo.a")}</h1>
          <div className="seen_date d-flex align-items-center">
            <BiTimeFive className="news_icon" />
            <span className="for_icon">
              30 {t("month.jul")} 2021 {t("month.year")}
            </span>
            <AiFillEye className="news_icon" />
            <span className="for_icon">0</span>
          </div>
        </div>
        <div className="row">
          <img src={narbayeva} alt="jpg" />
          <p style={{ textAlign: "justify" }}>
            <b>
              <Trans i18nKey={"odamsavdo.b"}>
                Сенатнинг 17 ялпи мажлисида ички ишлар вазири Пўлат Бобожонов
                2021 йилнинг биринчи ярмида ҳуқуқбузарликларнинг олдини олиш ва
                уларнинг профилактикаси ҳолати тўғрисидаги
                <Link to="/saylov">ахборот</Link> берди
              </Trans>
              .
            </b>
            .
          </p>
          <p style={{ textAlign: "justify" }}>{t("odamsavdo.c")}</p>
          <p style={{ textAlign: "justify" }}>{t("odamsavdo.d")}</p>
          <p style={{ textAlign: "justify" }}>{t("odamsavdo.e")}</p>
          <p style={{ textAlign: "justify" }}>
            <b>{t("odamsavdo.f")}</b>
          </p>
        </div>
      </div>
    </div>
  );
}

export default OdamSavdosi;
