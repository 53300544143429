import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import hokimiyat from "../Img/hokimiyat.jpg";
import Paginations from "./Pagination";

function Hokimiyat(props) {
  const lang = useSelector((state) => state.store.lang);
  const { t } = useTranslation();
  useEffect(() => {}, [lang]);
  return (
    <div className="hokimiyat">
      <h5 className="text-center pt-5">{t("work.u")}</h5>
      <div className="container">
        <div className="row">
          <div className="mycard2 col-md-8 mb-2 mt-3">
            <div className="row no-gutters">
              <div className="col-md-5">
                <img src={hokimiyat} id="img" alt="..." />
              </div>
              <div className="col-md-7">
                <div className="card_body2">
                  <h5 className="card-title">
                    <Link to="/hokimiyat-seventh-page/DavlatHizatlari">
                      {t("hokim7.a")}
                    </Link>
                  </h5>
                  <p className="card-text">
                    {t("hokim7.b")}
                    ...
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="mycard2 col-md-8 mb-2 mt-3">
            <div className="row no-gutters">
              <div className="col-md-5">
                <img src={hokimiyat} id="img" alt="..." />
              </div>
              <div className="col-md-7">
                <div className="card_body2">
                  <h5 className="card-title">
                    <Link to="/hokimiyat-seventh-page/AgroSanoat">
                      {t("hokim7.c")}
                    </Link>
                  </h5>
                  <p className="card-text">
                    {t("hokim7.d")}
                    ...
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="mycard2 col-md-8 mb-2 mt-3">
            <div className="row no-gutters">
              <div className="col-md-5">
                <img src={hokimiyat} id="img" alt="..." />
              </div>
              <div className="col-md-7">
                <div className="card_body2">
                  <h5 className="card-title">
                    <Link to="/hokimiyat-seventh-page/IqtisodiyotSanoat">
                      {t("hokim7.e")}
                    </Link>
                  </h5>
                  <p className="card-text">
                    {t("hokim7.f")}
                    ...
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Paginations />
      </div>
    </div>
  );
}

export default Hokimiyat;
