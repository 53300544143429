// src/reusable/image-gallery.component.js
import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

class ImageGalleryComponent extends React.Component {
  render() {
    return (
      <div>
        <Carousel autoPlay interval='5000' centerMode infiniteLoop>
          {this.props.images.map((img, index) => {
            return (
              <div key={index}>
                <img
                  src={`${this.props.api}/img/news/${img.filename}`}
                  alt={img.filename}
                />
                <p className='legend'>{this.props.name}</p>
              </div>
            );
          })}
        </Carousel>
      </div>
    );
  }
}

export default ImageGalleryComponent;
