import React, { useEffect } from "react";
import { AiFillEye } from "react-icons/ai";
import { BiTimeFive } from "react-icons/bi";
import "../Styles/News.css";
import savdo from "../Img/savdo.jpg";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

function Savdo() {
  const lang = useSelector((state) => state.store.lang);
  const { t } = useTranslation();
  useEffect(() => {}, [lang]);
  return (
    <div>
      <div className="container saylov">
        <div className="row">
          <h1>{t("savdo.a")}</h1>
          <div className="seen_date d-flex align-items-center">
            <BiTimeFive className="news_icon" />
            <span className="for_icon">
              30 {t("month.jul")} 2021 {t("month.year")}
            </span>
            <AiFillEye className="news_icon" />
            <span className="for_icon">0</span>
          </div>
        </div>
        <div className="row">
          <img src={savdo} alt="jpg" />
          <p style={{ textAlign: "center" }}>
            <p style={{ fontSize: "36px" }}>{t("savdo.b")}</p>.
          </p>

          <p style={{ textAlign: "center" }}>
            <b>{t("savdo.c")}</b>
          </p>
        </div>
      </div>
    </div>
  );
}

export default Savdo;
