import React, { useEffect } from "react";
import "aos/dist/aos.css";
import Aos from "aos";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

function Main_section() {
  const lang = useSelector((state) => state.store.lang);
  const { t } = useTranslation();
  useEffect(() => {}, [lang]);
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  return (
    <div className="main_section">
      <div className="container">
        <div
          className="news_box d-flex justify-content-between"
          data-aos="fade-right"
          data-aos-offset="400"
        >
          <h2 className="py-4">
            <a href="#!" className="text-decoration-none">
              {t("footer.i")}
            </a>
          </h2>
        </div>
        <div className="row" data-aos="fade-down" data-aos-offset="100">
          <div className="col-md-6 col-lg-3">
            <iframe
              width="100%"
              height="315"
              src="https://www.youtube.com/embed/Z-OAP5GH820"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
          <div className="col-md-6 col-lg-3">
            <iframe
              width="100%"
              height="315"
              src="https://www.youtube.com/embed/gxn0_JsU67I"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
          <div className="col-md-6 col-lg-3">
            <iframe
              width="100%"
              height="315"
              src="https://www.youtube.com/embed/8uIhAbr-kcE"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
          <div className="col-md-6 col-lg-3">
            <iframe
              width="100%"
              height="315"
              src="https://www.youtube.com/embed/jC_AyQ82TnA"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Main_section;
