import React, { useEffect } from "react";
import { AiFillEye } from "react-icons/ai";
import { BiTimeFive } from "react-icons/bi";
import "../Styles/News.css";
// import mahalla from "../Img/mahalla.jpg";
import { Row, Col } from "reactstrap";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

function Mahalla() {
  const lang = useSelector((state) => state.store.lang);
  const { t } = useTranslation();
  useEffect(() => {}, [lang]);
  return (
    <div>
      <div className="container">
        <div className="row">
          <h1>{t("dostlikdon.a")}</h1>
          <div className="seen_date d-flex align-items-center">
            <BiTimeFive className="news_icon" />
            <span className="for_icon">2021 {t("month.year")}</span>
            <AiFillEye className="news_icon" />
            <span className="for_icon">0</span>
          </div>
        </div>
        <div className="row">
          {/* <img src={mahalla} /> */}
          <p style={{ textAlign: "center" }}>
            <p style={{ fontSize: "36px" }}>{t("dostlikdon.b")} </p>.
          </p>
          <p>
            2018 {t("month.year")} 29 {t("month.avg")}:{" "}
          </p>
          <b>{t("dostlikdon.c")}</b>
          <Row className="d-flex justify-content-between">
            <Col lg={6}>
              <b>{t("work.a")}:</b>
              <p>03.03.1964 </p>
              <b>{t("work.b")}:</b>
              <p>{t("work.n")}</p>
              <b>{t("work.c")}:</b>
              <p>{t("work.o")}</p>
              <b>{t("work.d")}:</b>
              <span className="ml-4">{t("dostlikdon.d")} .</span>
              <br />
              <strong>{t("work.e")}:</strong>
              <p>{t("work.p")}</p>
            </Col>
            <Col lg={6}>
              <b>{t("work.f")}:</b>
              <p>{t("dostlikdon.e")}  </p>
              <b>{t("work.g")}:</b>
              <p>{t("work.q")}</p>
              <b>{t("work.h")}:</b>
              <p>{t("dostlikdon.f")}</p>
              <b> {t("work.i")}:</b>
              <p>{t("work.p")}</p>
            </Col>
          </Row>
          <b>{t("work.j")}: </b>
          <p>{t("work.s")}</p>
          <b>{t("work.k")}: </b>
          <p>{t("work.p")}</p>
          <b>{t("work.l")}:</b>
          <p>{t("work.p")}</p>
          <h3 className="text-center">{t("work.m")}</h3>
          <p>
            {t("dostlikdon.g")}
            <br />
            {t("dostlikdon.h")} <br />
            {t("dostlikdon.i")} <br />
            {t("dostlikdon.j")}
            <br />
            {t("dostlikdon.k")}
          </p>
        </div>
      </div>
    </div>
  );
}

export default Mahalla;
