import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Yangiliklar from "./Yangiliklar";
import Dolzarb from "./Dolzarb";
import axios from "axios";
import { useTranslation } from "react-i18next";
import DocumentMeta from "react-document-meta";

const YangilikIdPage = () => {
  const { api, lang } = useSelector((state) => state.store);

  // const API = useSelector((state) => state.store.api);
  // const lang = useSelector((state) => state.store.lang);

  const [loadingNewsId, setLoadingNewsId] = useState(false);
  const [loadingNewsDz, setLoadingNewsDz] = useState(false);
  const [meta, setMeta] = useState(null);
  const [post, setPost] = useState([]);
  const [postDz, setPostDz] = useState([]);
  const { t } = useTranslation();
  useEffect(() => {}, [lang]);
  const id = useParams().id;
  useEffect(() => {
    setLoadingNewsId(true);
    const getInfo = async () => {
      const { data } = await axios.get(`${api}/news/${id}`);
      setLoadingNewsId(false);
      const meta = {
        title: data?.name?.uz,
        description: data?.fulltext?.uz,
        canonical: `https://dustlik.uz/news/${id}`,
        meta: {
          charset: "utf-8",
          name: {
            keywords: "dustlik, hokimiyat, tuman, websayt",
          },
        },
      };
      setPost(data.news);
      setMeta(meta);
    };
    getInfo();

    const getDolzarb = async () => {
      setLoadingNewsDz(true);

      const { data } = await axios.get(`${api}/newsdolzarb`);
      setPostDz(data);
      setLoadingNewsDz(false);
    };
    // console.log(post, postDz);

    getDolzarb();
  }, [id, api]);
  return (
    <DocumentMeta {...meta}>
      <div className='news-id d-flex'>
        <div className='text-container-style col-lg-7 col-md-12'>
          {loadingNewsId ? (
            <div className='d-flex justify-content-center '>
              <div className='spinner-border m-2' role='status'>
                <span className='visually-hidden'>Loading...</span>
              </div>
            </div>
          ) : (
            <Yangiliklar
              key={post._id}
              post_id={post._id}
              images={post.images}
              name={post?.name}
              fulltext={post?.fulltext}
            />
          )}

          <Link
            className='news-back-style'
            to={{
              pathname: `/`,
              state: { id },
            }}
          >
            ← {t("work.ac")}
          </Link>
        </div>
        <div className='col-lg-3 col-md-12 card_right'>
          <div className='Sidebar'>
            <h1>{t("work.aa")}</h1>
            <div className='d-flex flex-column gap-2'>
              {loadingNewsDz ? (
                <div className='d-flex justify-content-center '>
                  <div className='spinner-border m-5' role='status'>
                    <span className='visually-hidden'>Loading...</span>
                  </div>
                </div>
              ) : (
                postDz.map((article, i) => {
                  return (
                    <Dolzarb
                      key={i}
                      name={
                        article.name
                          ? article.name[`${lang}`]
                          : article.name?.uz
                      }
                      images={article.images}
                      category={
                        article.Category.name
                          ? article.Category.name[`${lang}`]
                          : article.Category.name?.uz
                      }
                      alt={article.alt}
                      id={article._id}
                    />
                  );
                })
              )}
            </div>
          </div>
        </div>
      </div>
    </DocumentMeta>
  );
};
export default YangilikIdPage;
