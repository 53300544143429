import React, { useEffect } from "react";
import { AiFillEye } from "react-icons/ai";
import { BiTimeFive } from "react-icons/bi";
import "../Styles/News.css";
import vaksina from "../Img/vaksina.jpg";
import vaksina2 from "../Img/vaksina2.jpg";
import vaksina3 from "../Img/vaksina3.jpg";
import vaksina4 from "../Img/vaksina4.jpg";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

function Vaksina() {
  const lang = useSelector((state) => state.store.lang);
  const { t } = useTranslation();
  useEffect(() => {}, [lang]);
  return (
    <div>
      <div className="container saylov">
        <div className="row">
          <h1>{t("vaksina.a")}</h1>
          <div className="seen_date d-flex align-items-center">
            <BiTimeFive className="news_icon" />
            <span className="for_icon">
              30 {t("month.jul")} 2021 {t("month.year")}
            </span>
            <AiFillEye className="news_icon" />
            <span className="for_icon">0</span>
          </div>
        </div>
        <div className="row">
          <img src={vaksina} alt="jpg" />
          <p style={{ textAlign: "justify" }}>
            <b>{t("vaksina.b")}.</b>.
          </p>
          <p style={{ textAlign: "justify" }}> {t("vaksina.c")}.</p>
          <img src={vaksina2} alt="jpg" />
          <p style={{ textAlign: "justify" }}> {t("vaksina.d")}.</p>
          <img src={vaksina3} alt="jpg" />
          <img src={vaksina4} alt="jpg" />
          <p style={{ textAlign: "justify" }}>
            <i>{t("vaksina.e")}</i>
            {t("vaksina.f")}.
          </p>

          <p style={{ textAlign: "justify" }}>
            <b>{t("vaksina.g")}</b>
          </p>
        </div>
      </div>
    </div>
  );
}

export default Vaksina;
