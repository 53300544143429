import React, { useEffect } from "react";

import hokimiyat from "../Img/hokimiyat.jpg";
import Paginations from "./Pagination";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

function Hokimiyat(props) {
  const lang = useSelector((state) => state.store.lang);
  const { t } = useTranslation();
  useEffect(() => {}, [lang]);
  return (
    <div className="hokimiyat">
      <h5 className="text-center pt-5">{t("work.u")}</h5>
      <div className="container">
        <div className="row">
          <div className="mycard2 col-md-8 mb-2 mt-3">
            <div className="row no-gutters">
              <div className="col-md-5">
                <img src={hokimiyat} id="img" alt="..." />
              </div>
              <div className="col-md-7">
                <div className="card_body2">
                  <h5 className="card-title">
                    <Link to="/hokimiyat-second-page/xalq-banki">
                      {t("hokim2.a")}
                    </Link>
                  </h5>
                  <p className="card-text">
                    {t("hokim2.b")}
                    ...
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="mycard2 col-md-8 mb-2 mt-3">
            <div className="row no-gutters">
              <div className="col-md-5">
                <img src={hokimiyat} id="img" alt="..." />
              </div>
              <div className="col-md-7">
                <div className="card_body2">
                  <h5 className="card-title">
                    <Link to="/hokimiyat-second-page/ichki-ishlar">
                      {t("hokim2.c")}
                    </Link>
                  </h5>
                  <p className="card-text">
                    {t("hokim2.d")}
                    ...
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="mycard2 col-md-8 mb-2 mt-3">
            <div className="row no-gutters">
              <div className="col-md-5">
                <img src={hokimiyat} id="img" alt="..." />
              </div>
              <div className="col-md-7">
                <div className="card_body2">
                  <h5 className="card-title">
                    <Link to="/hokimiyat-second-page/savdogar-banki">
                      {t("hokim2.e")}
                    </Link>
                  </h5>
                  <p className="card-text">
                    {t("hokim2.f")}
                    ...
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="mycard2 col-md-8 mb-2 mt-3">
            <div className="row no-gutters">
              <div className="col-md-5">
                <img src={hokimiyat} id="img" alt="..." />
              </div>
              <div className="col-md-7">
                <div className="card_body2">
                  <h5 className="card-title">
                    <Link to="/hokimiyat-second-page/g`aznachilik-bo`limi-boshlog`i">
                      {t("hokim2.g")}
                    </Link>
                  </h5>
                  <p className="card-text">
                    {t("hokim2.h")}
                    ...
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="mycard2 col-md-8 mb-2 mt-3">
            <div className="row no-gutters">
              <div className="col-md-5">
                <img src={hokimiyat} id="img" alt="..." />
              </div>
              <div className="col-md-7">
                <div className="card_body2">
                  <h5 className="card-title">
                    <Link to="/hokimiyat-second-page/statistika">
                      {t("hokim2.i")}
                    </Link>
                  </h5>
                  <p className="card-text">
                    {t("hokim2.j")}
                    ...
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="mycard2 col-md-8 mb-2 mt-3">
            <div className="row no-gutters">
              <div className="col-md-5">
                <img src={hokimiyat} id="img" alt="..." />
              </div>
              <div className="col-md-7">
                <div className="card_body2">
                  <h5 className="card-title">
                    <Link to="/hokimiyat-second-page/dehqon-bozori-direktori">
                      {t("hokim2.k")}
                    </Link>
                  </h5>
                  <p className="card-text">
                    {t("hokim2.l")}
                    ...
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="mycard2 col-md-8 mb-2 mt-3">
            <div className="row no-gutters">
              <div className="col-md-5">
                <img src={hokimiyat} id="img" alt="..." />
              </div>
              <div className="col-md-7">
                <div className="card_body2">
                  <h5 className="card-title">
                    <Link to="/hokimiyat-second-page/mikrokredit-banki">
                      {t("hokim2.m")}
                    </Link>
                  </h5>
                  <p className="card-text">
                    {t("hokim2.n")}
                    ...
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="mycard2 col-md-8 mb-2 mt-3">
            <div className="row no-gutters">
              <div className="col-md-5">
                <img src={hokimiyat} id="img" alt="..." />
              </div>
              <div className="col-md-7">
                <div className="card_body2">
                  <h5 className="card-title">
                    <Link to="/hokimiyat-second-page/tadbirkorlar-palatasi">
                      {t("hokim2.o")}
                    </Link>
                  </h5>
                  <p className="card-text">
                    {t("hokim2.p")}
                    ...
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="mycard2 col-md-8 mb-2 mt-3">
            <div className="row no-gutters">
              <div className="col-md-5">
                <img src={hokimiyat} id="img" alt="..." />
              </div>
              <div className="col-md-7">
                <div className="card_body2">
                  <h5 className="card-title">
                    <Link to="/hokimiyat-second-page/yoshlar-ittifoqi-ijtimoiy-qollab-quvatlash">
                      {t("hokim2.q")}
                    </Link>
                  </h5>
                  <p className="card-text">
                    {t("hokim2.r")}
                    ...
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="mycard2 col-md-8 mb-2 mt-3">
            <div className="row no-gutters">
              <div className="col-md-5">
                <img src={hokimiyat} id="img" alt="..." />
              </div>
              <div className="col-md-7">
                <div className="card_body2">
                  <h5 className="card-title">
                    <Link to="/hokimiyat-second-page/pensiya-jamgarmasi">
                      {t("hokim2.s")}
                    </Link>
                  </h5>
                  <p className="card-text">
                    {t("hokim2.t")}
                    ...
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Paginations />
      </div>
    </div>
  );
}

export default Hokimiyat;
