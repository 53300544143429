import React, { useEffect } from "react";
import { AiFillEye } from "react-icons/ai";
import { BiTimeFive } from "react-icons/bi";
import "../Styles/News.css";
import { Row, Col } from "reactstrap";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

function Mahalla() {
  const lang = useSelector((state) => state.store.lang);
  const { t } = useTranslation();
  useEffect(() => {}, [lang]);
  return (
    <div>
      <div className="container">
        <div className="row">
          <h1>{t("agrobank.a")}</h1>
          <div className="seen_date d-flex align-items-center">
            <BiTimeFive className="news_icon" />
            <span className="for_icon">
              30 {t("month.jul")} 2021 {t("month.year")}
            </span>
            <AiFillEye className="news_icon" />
            <span className="for_icon">172</span>
          </div>
        </div>
        <div className="row">
          {/* <img src={mahalla} /> */}
          <p style={{ textAlign: "center" }}>
            <p style={{ fontSize: "36px" }}>{t("agrobank.b")} </p>.
          </p>
          <p>
            2020 {t("month.year")} 12 {t("month.nov")}:
          </p>
          <b>{t("agrobank.a")}</b>
          <Row className="d-flex justify-content-between">
            <Col lg={6}>
              <b>{t("work.a")}:</b>
              <p>
                24.07.1988 <br />
                {t("agrobank.c")}
              </p>
              <b>{t("work.b")}:</b>
              <p>{t("work.n")}</p>
              <b>{t("work.c")}:</b>
              <p>{t("agrobank.d")}</p>
              <b>{t("work.d")}:</b>
              <span className="ml-4">{t("agrobank.e")}</span> <br />
              <strong>{t("work.e")}:</strong>
              <p>{t("work.p")}</p>
            </Col>
            <Col lg={6}>
              <b>{t("work.f")}:</b>
              <p>{t("work.r")}</p>
              <b>{t("work.g")}:</b>
              <p>{t("work.p")}</p>
              <b>{t("work.h")}:</b>
              <p>{t("agrobank.f")}</p>
              <b> {t("work.i")}:</b>
              <p>{t("work.p")}</p>
            </Col>
          </Row>
          <b>{t("work.j")}: </b>
          <p>{t("work.s")}</p>
          <b>{t("work.k")}</b>
          <p>{t("work.p")}</p>
          <b>{t("work.l")} :</b>
          <p>{t("agrobank.a")}</p>
          <h3 className="text-center">{t("work.m")}</h3>
          <p>{t("agrobank.g")}</p>
          <p>{t("agrobank.h")}</p>
          <p>{t("agrobank.i")}</p>
          <p>{t("agrobank.j")}</p>
          <p>{t("agrobank.k")}</p>
          <p>{t("agrobank.l")}</p>
          <p>{t("agrobank.m")}</p>
          <p>{t("agrobank.n")}</p>
          <p>{t("agrobank.o")}</p>
          <p>{t("agrobank.p")}</p>
          <p>{t("agrobank.q")}</p>
          <p>{t("agrobank.r")}</p>
          <p>{t("agrobank.s")}</p>
          <p>{t("agrobank.t")}</p>
          <p>{t("agrobank.u")}</p>
          <p>{t("agrobank.v")}</p>
          <p>{t("agrobank.w")}</p>
        </div>
      </div>
    </div>
  );
}

export default Mahalla;
