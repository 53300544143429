import React, { useEffect } from "react";
import "../Styles/News.css";
import { AiFillEye } from "react-icons/ai";
import { BiTimeFive } from "react-icons/bi";
import { Row, Col } from "reactstrap";
import { useSelector } from "react-redux";
import { Trans, useTranslation } from "react-i18next";

function Mahalla() {
  const lang = useSelector((state) => state.store.lang);
  const { t } = useTranslation();
  useEffect(() => {}, [lang]);
  return (
    <div>
      <div className="container">
        <div className="row">
          <h1>{t("adliya.a")}</h1>
          <div className="seen_date d-flex align-items-center">
            <BiTimeFive className="news_icon" />
            <span className="for_icon">2021 {t("month.year")}</span>
            <AiFillEye className="news_icon" />
            <span className="for_icon">0</span>
          </div>
        </div>
        <div className="row">
          <p style={{ textAlign: "center" }}>
            <p style={{ fontSize: "36px" }}>{t("adliya.b")}</p>.
          </p>
          <p>{t("adliya.c")}: </p>
          <b> {t("adliya.c")}</b>
          <Row className="d-flex justify-content-between">
            <Col lg={6}>
              <b>{t("work.a")}:</b>
              <p> 27.05.1981 </p>
              <b>{t("work.b")}:</b>
              <p>{t("work.n")}</p>
              <b>{t("work.c")}:</b>
              <p>{t("work.o")}</p>
              <b>{t("work.d")}:</b>
              <span className="ml-4">{t("adliya.e")} </span> <br />
              <strong>{t("work.e")}:</strong>
              <p>{t("work.p")}</p>
            </Col>
            <Col lg={6}>
              <b>{t("work.f")}:</b>
              <p>{t("adliya.f")} </p>
              <b>{t("work.g")}:</b>
              <p>{t("work.q")} </p>
              <b>{t("work.h")}:</b>
              <p>
                <Trans i18nKey="adliya.g">
                  2004 й. Самарқанд Давлат университети (кундузги) 2015 й.
                  <br />
                  Ўзбекистон Республикаси Президенти ҳузуридаги Давлат бошқаруви
                  академияси (кундузги)
                </Trans>
              </p>
              <b> {t("work.i")}:</b>
              <p>{t("work.p")}</p>
            </Col>
          </Row>
          <b>{t("work.j")}: </b>
          <p>{t("adliya.h")}</p>
          <b>{t("work.k")}: </b>
          <p>{t("adliya.i")}</p>
          <b>{t("work.l")}:</b>
          <p>{t("work.p")}</p>
          <h3 className="text-center">{t("work.m")}</h3>
          <p>{t("adliya.j")}</p>
          <br />
          <p>{t("adliya.k")}</p>
          <br />
          <p>{t("adliya.l")}</p>
          <br />
          <p>{t("adliya.m")}</p>
          <br />
          <p>{t("adliya.n")}</p>
          <br />
          <p>{t("adliya.o")}</p>
          <br />
          <p>{t("adliya.p")}</p>
          <br />
          <p>{t("adliya.q")}</p>
          <br />
          <p>{t("adliya.r")}</p>
          <br />
          <p>{t("adliya.s")}</p>
          <br />
          <p>{t("adliya.t")}</p>
          <br />
          <p>{t("adliya.u")}</p>
        </div>
      </div>
    </div>
  );
}

export default Mahalla;
