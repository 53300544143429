import React, { useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link } from "react-router-dom";

import "../Styles/Main.css";
import Aos from "aos";
import "aos/dist/aos.css";
import aholi from "../Img/aholi.jpg";
import vaksina from "../Img/vaksina.jpg";
import oliy from "../Img/oliy.png";
import masala from "../Img/masala.jpg";
import xiyobon from "../Img/xiyobon.jpg";
import narbayeva from "../Img/narbayev.jpg";
import saylov from "../Img/saylov.jpg";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
function Main_top() {
  const lang = useSelector((state) => state.store.lang);
  const { t } = useTranslation();
  useEffect(() => {}, [lang]);
  useEffect(() => {
    Aos.init({ duration: 600 });
  }, []);
  return (
    <div className="main">
      <div className="container">
        <div className="row">
          <div className="col-lg-8">
            <div className="row">
              <div className="col-12">
                <h1 className="pb-2">
                  <Link to="#!" name="news" className="text-decoration-none">
                    {t("footer.e")}
                  </Link>
                </h1>
              </div>
              <div className="col-lg-6 col-md-8 mt-3">
                <div
                  className="mycard"
                  data-aos="fade-right"
                  data-aos-offset="100"
                >
                  <img src={aholi} className="img-fluid" alt="first" />
                  <div className="card_top">{t("work.z")}</div>
                  <div className="card_body">
                    <p className="card-text">{t("mainto.a")}</p>
                    <p className="more">
                      <Link to="/aholi">
                        {t("work.y")}
                        <svg
                          stroke="currentColor"
                          fill="currentColor"
                          stroke-width="0"
                          viewBox="0 0 16 16"
                          className="ml-2"
                          height="1em"
                          width="1em"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M10.146 4.646a.5.5 0 01.708 0l3 3a.5.5 0 010 .708l-3 3a.5.5 0 01-.708-.708L12.793 8l-2.647-2.646a.5.5 0 010-.708z"
                            clip-rule="evenodd"
                          ></path>
                          <path
                            fill-rule="evenodd"
                            d="M2 8a.5.5 0 01.5-.5H13a.5.5 0 010 1H2.5A.5.5 0 012 8z"
                            clip-rule="evenodd"
                          ></path>
                        </svg>
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-8 mt-3">
                <div
                  className="mycard"
                  data-aos="fade-down"
                  data-aos-offset="100"
                >
                  <img src={saylov} className="img-fluid" alt="first" />
                  <div className="card_top">{t("work.z")}</div>
                  <div className="card_body">
                    <p className="card-text">{t("mainto.b")}</p>
                    <p className="more">
                      <Link to="/aholi">
                        {t("work.y")}
                        <svg
                          stroke="currentColor"
                          fill="currentColor"
                          stroke-width="0"
                          viewBox="0 0 16 16"
                          className="ml-2"
                          height="1em"
                          width="1em"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M10.146 4.646a.5.5 0 01.708 0l3 3a.5.5 0 010 .708l-3 3a.5.5 0 01-.708-.708L12.793 8l-2.647-2.646a.5.5 0 010-.708z"
                            clip-rule="evenodd"
                          ></path>
                          <path
                            fill-rule="evenodd"
                            d="M2 8a.5.5 0 01.5-.5H13a.5.5 0 010 1H2.5A.5.5 0 012 8z"
                            clip-rule="evenodd"
                          ></path>
                        </svg>
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-8 mt-3">
                <div
                  className="mycard"
                  data-aos="fade-right"
                  data-aos-offset="100"
                >
                  <img src={oliy} className="img-fluid" alt="first" />
                  <div className="card_top">{t("work.z")}</div>
                  <div className="card_body">
                    <p className="card-text">{t("mainto.c")}</p>
                    <p className="more">
                      <Link to="/aholi">
                        {t("work.y")}
                        <svg
                          stroke="currentColor"
                          fill="currentColor"
                          stroke-width="0"
                          viewBox="0 0 16 16"
                          className="ml-2"
                          height="1em"
                          width="1em"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M10.146 4.646a.5.5 0 01.708 0l3 3a.5.5 0 010 .708l-3 3a.5.5 0 01-.708-.708L12.793 8l-2.647-2.646a.5.5 0 010-.708z"
                            clip-rule="evenodd"
                          ></path>
                          <path
                            fill-rule="evenodd"
                            d="M2 8a.5.5 0 01.5-.5H13a.5.5 0 010 1H2.5A.5.5 0 012 8z"
                            clip-rule="evenodd"
                          ></path>
                        </svg>
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-8 mt-3">
                <div
                  className="mycard"
                  data-aos="fade-right"
                  data-aos-offset="100"
                >
                  <img src={narbayeva} className="img-fluid" alt="first" />
                  <div className="card_top">{t("work.z")}</div>
                  <div className="card_body">
                    <p className="card-text">{t("mainto.d")}</p>
                    <p className="more">
                      <Link to="/odamsavdosi">
                        {t("work.y")}
                        <svg
                          stroke="currentColor"
                          fill="currentColor"
                          stroke-width="0"
                          viewBox="0 0 16 16"
                          className="ml-2"
                          height="1em"
                          width="1em"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M10.146 4.646a.5.5 0 01.708 0l3 3a.5.5 0 010 .708l-3 3a.5.5 0 01-.708-.708L12.793 8l-2.647-2.646a.5.5 0 010-.708z"
                            clip-rule="evenodd"
                          ></path>
                          <path
                            fill-rule="evenodd"
                            d="M2 8a.5.5 0 01.5-.5H13a.5.5 0 010 1H2.5A.5.5 0 012 8z"
                            clip-rule="evenodd"
                          ></path>
                        </svg>
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* left bar */}
          <div className="col-lg-4">
            <div className="col-12">
              <h1 className="pb-2">{t("work.aa")}</h1>
            </div>
            <div
              className="mycard2 mb-3 mt-4"
              data-aos="fade-left"
              data-aos-offset="100"
            >
              <div className="row no-gutters">
                <div className="col-md-5">
                  <img src={vaksina} id="img" alt="..." />
                </div>
                <div className="col-md-7">
                  <div className="card_body2">
                    <h5 className="card-title">{t("mainto.e")}</h5>
                    <p className="card-text">{t("mainto.f")} ...</p>
                    <p className="more">
                      <Link to="/vaksina" className="mycard_2_a">
                        {t("work.y")}
                        <svg
                          stroke="currentColor"
                          fill="currentColor"
                          stroke-width="0"
                          viewBox="0 0 16 16"
                          className="ml-2"
                          height="1em"
                          width="1em"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M10.146 4.646a.5.5 0 01.708 0l3 3a.5.5 0 010 .708l-3 3a.5.5 0 01-.708-.708L12.793 8l-2.647-2.646a.5.5 0 010-.708z"
                            clip-rule="evenodd"
                          ></path>
                          <path
                            fill-rule="evenodd"
                            d="M2 8a.5.5 0 01.5-.5H13a.5.5 0 010 1H2.5A.5.5 0 012 8z"
                            clip-rule="evenodd"
                          ></path>
                        </svg>
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="mycard2 mb-3 mt-4"
              data-aos="fade-left"
              data-aos-offset="100"
            >
              <div className="row no-gutters">
                <div className="col-md-5">
                  <img src={oliy} id="img" alt="..." />
                </div>
                <div className="col-md-7">
                  <div className="card_body2">
                    <h5 className="card-title">{t("mainto.g")} ...</h5>
                    <p className="card-text">
                      {t("mainto.h")}
                      ...
                    </p>
                    <p className="more">
                      <Link to="/qaror" className="mycard_2_a">
                        {t("work.y")}
                        <svg
                          stroke="currentColor"
                          fill="currentColor"
                          stroke-width="0"
                          viewBox="0 0 16 16"
                          className="ml-2"
                          height="1em"
                          width="1em"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M10.146 4.646a.5.5 0 01.708 0l3 3a.5.5 0 010 .708l-3 3a.5.5 0 01-.708-.708L12.793 8l-2.647-2.646a.5.5 0 010-.708z"
                            clip-rule="evenodd"
                          ></path>
                          <path
                            fill-rule="evenodd"
                            d="M2 8a.5.5 0 01.5-.5H13a.5.5 0 010 1H2.5A.5.5 0 012 8z"
                            clip-rule="evenodd"
                          ></path>
                        </svg>
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="mycard2 mb-3 mt-4"
              data-aos="fade-left"
              data-aos-offset="100"
            >
              <div className="row no-gutters">
                <div className="col-md-5">
                  <img src={xiyobon} id="img" alt="..." />
                </div>
                <div className="col-md-7">
                  <div className="card_body2">
                    <h5 className="card-title">{t("mainto.i")}</h5>
                    <p className="card-text">
                      {t("mainto.j")}
                      ....
                    </p>
                    <p className="more">
                      <Link to="/xiyobon" className="mycard_2_a">
                        {t("work.y")}
                        <svg
                          stroke="currentColor"
                          fill="currentColor"
                          stroke-width="0"
                          viewBox="0 0 16 16"
                          className="ml-2"
                          height="1em"
                          width="1em"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M10.146 4.646a.5.5 0 01.708 0l3 3a.5.5 0 010 .708l-3 3a.5.5 0 01-.708-.708L12.793 8l-2.647-2.646a.5.5 0 010-.708z"
                            clip-rule="evenodd"
                          ></path>
                          <path
                            fill-rule="evenodd"
                            d="M2 8a.5.5 0 01.5-.5H13a.5.5 0 010 1H2.5A.5.5 0 012 8z"
                            clip-rule="evenodd"
                          ></path>
                        </svg>
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="mycard2 mb-3 mt-4"
              data-aos="fade-left"
              data-aos-offset="100"
            >
              <div className="row no-gutters">
                <div className="col-md-5">
                  <img src={masala} id="img" alt="..." />
                </div>
                <div className="col-md-7">
                  <div className="card_body2">
                    <h5 className="card-title">{t("mainto.k")}</h5>
                    <p className="card-text">
                      {t("mainto.l")}
                      ...
                    </p>
                    <p className="more">
                      <Link to="/masala" className="mycard_2_a">
                        {t("work.y")}
                        <svg
                          stroke="currentColor"
                          fill="currentColor"
                          stroke-width="0"
                          viewBox="0 0 16 16"
                          className="ml-2"
                          height="1em"
                          width="1em"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M10.146 4.646a.5.5 0 01.708 0l3 3a.5.5 0 010 .708l-3 3a.5.5 0 01-.708-.708L12.793 8l-2.647-2.646a.5.5 0 010-.708z"
                            clip-rule="evenodd"
                          ></path>
                          <path
                            fill-rule="evenodd"
                            d="M2 8a.5.5 0 01.5-.5H13a.5.5 0 010 1H2.5A.5.5 0 012 8z"
                            clip-rule="evenodd"
                          ></path>
                        </svg>
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Main_top;
