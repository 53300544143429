import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import hokimiyat from "../Img/hokimiyat.jpg";
import Paginations from "./Pagination";

function Hokimiyat(props) {
  const lang = useSelector((state) => state.store.lang);
  const { t } = useTranslation();
  useEffect(() => {}, [lang]);
  return (
    <div className="hokimiyat">
      <h5 className="text-center pt-5">{t("work.u")}</h5>
      <div className="container">
        <div className="row">
          <div class="mycard2 col-md-8 mb-2 mt-3">
            <div class="row no-gutters">
              <div class="col-md-5">
                <img src={hokimiyat} id="img" alt="..." />
              </div>
              <div class="col-md-7">
                <div class="card_body2">
                  <h5 class="card-title">
                    <Link to="/hokimiyat-sixth-page/pillachilik">
                      {t("hokim6.a")}
                    </Link>
                  </h5>
                  <p class="card-text">
                    {t("hokim6.b")}
                    ...
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div class="mycard2 col-md-8 mb-2 mt-3">
            <div class="row no-gutters">
              <div class="col-md-5">
                <img src={hokimiyat} id="img" alt="..." />
              </div>
              <div class="col-md-7">
                <div class="card_body2">
                  <h5 class="card-title">
                    <Link to="/hokimiyat-sixth-page/agro-sanot">
                      {t("hokim6.c")}
                    </Link>
                  </h5>
                  <p class="card-text">
                    {t("hokim6.d")}
                    ...
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div class="mycard2 col-md-8 mb-2 mt-3">
            <div class="row no-gutters">
              <div class="col-md-5">
                <img src={hokimiyat} id="img" alt="..." />
              </div>
              <div class="col-md-7">
                <div class="card_body2">
                  <h5 class="card-title">
                    <Link to="/hokimiyat-sixth-page/Агротехсервис">
                      {t("hokim6.e")}
                    </Link>
                  </h5>
                  <p class="card-text">
                    {t("hokim6.f")}
                    ...
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div class="mycard2 col-md-8 mb-2 mt-3">
            <div class="row no-gutters">
              <div class="col-md-5">
                <img src={hokimiyat} id="img" alt="..." />
              </div>
              <div class="col-md-7">
                <div class="card_body2">
                  <h5 class="card-title">
                    <Link to="/hokimiyat-sixth-page/Soliq">
                      {t("hokim6.g")}
                    </Link>
                  </h5>
                  <p class="card-text">
                    {t("hokim6.h")}
                    ...
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div class="mycard2 col-md-8 mb-2 mt-3">
            <div class="row no-gutters">
              <div class="col-md-5">
                <img src={hokimiyat} id="img" alt="..." />
              </div>
              <div class="col-md-7">
                <div class="card_body2">
                  <h5 class="card-title">
                    <Link to="/hokimiyat-sixth-page/Adliya">
                      {t("hokim6.i")}
                    </Link>
                  </h5>
                  <p class="card-text">
                    {t("hokim6.j")}
                    ...
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div class="mycard2 col-md-8 mb-2 mt-3">
            <div class="row no-gutters">
              <div class="col-md-5">
                <img src={hokimiyat} id="img" alt="..." />
              </div>
              <div class="col-md-7">
                <div class="card_body2">
                  <h5 class="card-title">
                    <Link to="/hokimiyat-sixth-page/XalqQabulxonasi">
                      {t("hokim6.k")}
                    </Link>
                  </h5>
                  <p class="card-text">
                    {t("hokim6.l")}
                    ...
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div class="mycard2 col-md-8 mb-2 mt-3">
            <div class="row no-gutters">
              <div class="col-md-5">
                <img src={hokimiyat} id="img" alt="..." />
              </div>
              <div class="col-md-7">
                <div class="card_body2">
                  <h5 class="card-title">
                    <Link to="/hokimiyat-sixth-page/TadbirkorlarMurojati">
                      {t("hokim6.m")}
                    </Link>
                  </h5>
                  <p class="card-text">
                    {t("hokim6.n")}
                    ...
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div class="mycard2 col-md-8 mb-2 mt-3">
            <div class="row no-gutters">
              <div class="col-md-5">
                <img src={hokimiyat} id="img" alt="..." />
              </div>
              <div class="col-md-7">
                <div class="card_body2">
                  <h5 class="card-title">
                    <Link to="/hokimiyat-sixth-page/JizzaxNeft">
                      {t("hokim6.o")}
                    </Link>
                  </h5>
                  <p class="card-text">
                    {t("hokim6.p")}
                    ...
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div class="mycard2 col-md-8 mb-2 mt-3">
            <div class="row no-gutters">
              <div class="col-md-5">
                <img src={hokimiyat} id="img" alt="..." />
              </div>
              <div class="col-md-7">
                <div class="card_body2">
                  <h5 class="card-title">
                    <Link to="/hokimiyat-sixth-page/Дўстликдонмахсулотлари">
                      {t("hokim6.q")}
                    </Link>
                  </h5>
                  <p class="card-text">
                    {t("hokim6.r")}
                    ...
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div class="mycard2 col-md-8 mb-2 mt-3">
            <div class="row no-gutters">
              <div class="col-md-5">
                <img src={hokimiyat} id="img" alt="..." />
              </div>
              <div class="col-md-7">
                <div class="card_body2">
                  <h5 class="card-title">
                    <Link to="/hokimiyat-sixth-page/NurliDon">
                      {t("hokim6.s")}
                    </Link>
                  </h5>
                  <p class="card-text">
                    {t("hokim6.t")}
                    ...
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Paginations />
      </div>
    </div>
  );
}

export default Hokimiyat;
